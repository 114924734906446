import React from 'react';

import Separator from '~/components/Separator';

import { useAppLanguage } from '~/hooks/language';
import { getLanguageInfo } from '~/utils';

import {
  MainContainer,
  ContentContainer,
  ContentTitle,
  ContentSubtitle,
  ContentTextWrapper,
  ContentSpanTextWrapper,
  ContentText,
  ContentHighlightedText,
  ContentSpanText,
  ContentSpanHighlightedText,
  ContentItemsWrapper,
  ContentItemWrapper,
  ContentItemIndexWrapper,
  ContentItemInfoWrapper,
  ContentItemIndexFirstType,
  ContentItemTitleFirstType,
  ContentItemText,
  ContentItemExtraText,
} from './styles';

const Main = (): JSX.Element => {
  const { currentLanguage } = useAppLanguage();

  return (
    <MainContainer id="cookiespolicy">
      <ContentContainer>
        <ContentTitle>
          {getLanguageInfo(currentLanguage, 'cookies_main_title')}
        </ContentTitle>

        <ContentSpanTextWrapper>
          <ContentSpanText>
            {getLanguageInfo(currentLanguage, 'cookies_main_text_01')}
          </ContentSpanText>

          <ContentSpanHighlightedText>
            {getLanguageInfo(
              currentLanguage,
              'cookies_main_text_02_highlighted',
            )}
          </ContentSpanHighlightedText>

          <ContentSpanText>
            {getLanguageInfo(currentLanguage, 'cookies_main_text_03')}
          </ContentSpanText>
        </ContentSpanTextWrapper>
      </ContentContainer>

      <ContentContainer>
        <ContentSubtitle>
          {getLanguageInfo(currentLanguage, 'cookies_what_are_cookies_title')}
        </ContentSubtitle>

        <ContentTextWrapper>
          <ContentText>
            {getLanguageInfo(
              currentLanguage,
              'cookies_what_are_cookies_text_01',
            )}
          </ContentText>
        </ContentTextWrapper>
      </ContentContainer>

      <ContentContainer>
        <ContentSubtitle>
          {getLanguageInfo(
            currentLanguage,
            'cookies_which_cookies_we_collect_title',
          )}
        </ContentSubtitle>

        <ContentTextWrapper>
          <ContentText>
            {getLanguageInfo(
              currentLanguage,
              'cookies_which_cookies_we_collect_text_01',
            )}
          </ContentText>
        </ContentTextWrapper>

        <ContentItemsWrapper>
          <ContentItemWrapper>
            <ContentItemIndexWrapper>
              <ContentItemIndexFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'cookies_which_cookies_we_collect_item_01_index',
                )}
              </ContentItemIndexFirstType>
            </ContentItemIndexWrapper>

            <ContentItemInfoWrapper>
              <ContentItemTitleFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'cookies_which_cookies_we_collect_item_01_title',
                )}
              </ContentItemTitleFirstType>

              <ContentItemText>
                {getLanguageInfo(
                  currentLanguage,
                  'cookies_which_cookies_we_collect_item_01_text',
                )}
              </ContentItemText>
            </ContentItemInfoWrapper>
          </ContentItemWrapper>

          <ContentItemWrapper>
            <ContentItemIndexWrapper>
              <ContentItemIndexFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'cookies_which_cookies_we_collect_item_02_index',
                )}
              </ContentItemIndexFirstType>
            </ContentItemIndexWrapper>

            <ContentItemInfoWrapper>
              <ContentItemTitleFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'cookies_which_cookies_we_collect_item_02_title',
                )}
              </ContentItemTitleFirstType>

              <ContentItemText>
                {getLanguageInfo(
                  currentLanguage,
                  'cookies_which_cookies_we_collect_item_02_text_01',
                )}
              </ContentItemText>

              <ContentItemExtraText>
                {getLanguageInfo(
                  currentLanguage,
                  'cookies_which_cookies_we_collect_item_02_text_02',
                )}
              </ContentItemExtraText>

              <ContentItemExtraText>
                {getLanguageInfo(
                  currentLanguage,
                  'cookies_which_cookies_we_collect_item_02_text_03',
                )}
              </ContentItemExtraText>
            </ContentItemInfoWrapper>
          </ContentItemWrapper>

          <ContentItemWrapper>
            <ContentItemIndexWrapper>
              <ContentItemIndexFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'cookies_which_cookies_we_collect_item_03_index',
                )}
              </ContentItemIndexFirstType>
            </ContentItemIndexWrapper>

            <ContentItemInfoWrapper>
              <ContentItemTitleFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'cookies_which_cookies_we_collect_item_03_title',
                )}
              </ContentItemTitleFirstType>

              <ContentItemText>
                {getLanguageInfo(
                  currentLanguage,
                  'cookies_which_cookies_we_collect_item_03_text',
                )}
              </ContentItemText>
            </ContentItemInfoWrapper>
          </ContentItemWrapper>
        </ContentItemsWrapper>
      </ContentContainer>

      <ContentContainer>
        <ContentSubtitle>
          {getLanguageInfo(currentLanguage, 'cookies_categories_title')}
        </ContentSubtitle>

        <ContentTextWrapper>
          <ContentText>
            {getLanguageInfo(currentLanguage, 'cookies_categories_text_01')}
          </ContentText>
        </ContentTextWrapper>

        <ContentItemsWrapper>
          <ContentItemWrapper>
            <ContentItemIndexWrapper>
              <ContentItemIndexFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'cookies_categories_item_01_index',
                )}
              </ContentItemIndexFirstType>
            </ContentItemIndexWrapper>

            <ContentItemInfoWrapper>
              <ContentItemTitleFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'cookies_categories_item_01_title',
                )}
              </ContentItemTitleFirstType>

              <ContentItemText>
                {getLanguageInfo(
                  currentLanguage,
                  'cookies_categories_item_01_text',
                )}
              </ContentItemText>
            </ContentItemInfoWrapper>
          </ContentItemWrapper>

          <ContentItemWrapper>
            <ContentItemIndexWrapper>
              <ContentItemIndexFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'cookies_categories_item_02_index',
                )}
              </ContentItemIndexFirstType>
            </ContentItemIndexWrapper>

            <ContentItemInfoWrapper>
              <ContentItemTitleFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'cookies_categories_item_02_title',
                )}
              </ContentItemTitleFirstType>

              <ContentItemText>
                {getLanguageInfo(
                  currentLanguage,
                  'cookies_categories_item_02_text',
                )}
              </ContentItemText>
            </ContentItemInfoWrapper>
          </ContentItemWrapper>

          <ContentItemWrapper>
            <ContentItemIndexWrapper>
              <ContentItemIndexFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'cookies_categories_item_03_index',
                )}
              </ContentItemIndexFirstType>
            </ContentItemIndexWrapper>

            <ContentItemInfoWrapper>
              <ContentItemTitleFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'cookies_categories_item_03_title',
                )}
              </ContentItemTitleFirstType>

              <ContentItemText>
                {getLanguageInfo(
                  currentLanguage,
                  'cookies_categories_item_03_text',
                )}
              </ContentItemText>
            </ContentItemInfoWrapper>
          </ContentItemWrapper>

          <ContentItemWrapper>
            <ContentItemIndexWrapper>
              <ContentItemIndexFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'cookies_categories_item_04_index',
                )}
              </ContentItemIndexFirstType>
            </ContentItemIndexWrapper>

            <ContentItemInfoWrapper>
              <ContentItemTitleFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'cookies_categories_item_04_title',
                )}
              </ContentItemTitleFirstType>

              <ContentItemText>
                {getLanguageInfo(
                  currentLanguage,
                  'cookies_categories_item_04_text',
                )}
              </ContentItemText>
            </ContentItemInfoWrapper>
          </ContentItemWrapper>
        </ContentItemsWrapper>
      </ContentContainer>

      <ContentContainer>
        <ContentTextWrapper>
          <ContentHighlightedText>
            {getLanguageInfo(currentLanguage, 'cookies_end_highlighted_text')}
          </ContentHighlightedText>
        </ContentTextWrapper>
      </ContentContainer>

      <Separator />
    </MainContainer>
  );
};

export default Main;
