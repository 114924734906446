import React from 'react';

import Separator from '~/components/Separator';

import { useAppLanguage } from '~/hooks/language';
import { getLanguageInfo } from '~/utils';

import {
  MainContainer,
  Title,
  Subtitle,
  SubtitleHighLight,
  ArrowLargeScreenContainer,
  ArrowLargeScreen,
  ArrowSmallScreenContainer,
  ArrowSmallScreen,
  MainContent,
  FlipContainer,
  Flipper,
  Front,
  Back,
  ContentItemMissionComplete,
  ContentItemVisionComplete,
  ContentItemValuesComplete,
  ContentItemMissionBorder,
  ContentItemVisionBorder,
  ContentItemValuesBorder,
  ContentItemMissionText,
  ContentItemVisionText,
  ContentItemValuesText,
  ExtraInfoTopLine,
  ExtraInfoBottomLine,
  ExtraInfoHighLight,
} from './styles';

const AboutUs = (): JSX.Element => {
  const { currentLanguage } = useAppLanguage();

  function handleItemMissionComplete(): React.ReactNode {
    if (currentLanguage === 'english') {
      return (
        <ContentItemMissionComplete src="images/quem_somos/completo_missao_en.png" />
      );
    }

    if (currentLanguage === 'spanish') {
      return (
        <ContentItemMissionComplete src="images/quem_somos/completo_missao_es.png" />
      );
    }

    return (
      <ContentItemMissionComplete src="images/quem_somos/completo_missao_pt.png" />
    );
  }

  function handleItemVisionComplete(): React.ReactNode {
    if (currentLanguage === 'english') {
      return (
        <ContentItemVisionComplete src="images/quem_somos/completo_visao_en.png" />
      );
    }

    if (currentLanguage === 'spanish') {
      return (
        <ContentItemVisionComplete src="images/quem_somos/completo_visao_es.png" />
      );
    }

    return (
      <ContentItemVisionComplete src="images/quem_somos/completo_visao_pt.png" />
    );
  }

  function handleItemValuesComplete(): React.ReactNode {
    if (currentLanguage === 'english') {
      return (
        <ContentItemValuesComplete
          isPortuguese={false}
          src="images/quem_somos/completo_valores_en.png"
        />
      );
    }

    if (currentLanguage === 'spanish') {
      return (
        <ContentItemValuesComplete
          isPortuguese={false}
          src="images/quem_somos/completo_valores_es.png"
        />
      );
    }

    return (
      <ContentItemValuesComplete
        isPortuguese
        src="images/quem_somos/completo_valores_pt.png"
      />
    );
  }

  return (
    <MainContainer id="aboutus">
      <Title>{getLanguageInfo(currentLanguage, 'aboutus_title')}</Title>

      <Subtitle>
        {getLanguageInfo(currentLanguage, 'aboutus_subtitle_01')}

        <SubtitleHighLight>
          {getLanguageInfo(currentLanguage, 'aboutus_subtitle_02')}
        </SubtitleHighLight>

        {getLanguageInfo(currentLanguage, 'aboutus_subtitle_03')}
      </Subtitle>

      <ArrowLargeScreenContainer>
        <ArrowLargeScreen src="images/quem_somos/flecha_grande.png" />
      </ArrowLargeScreenContainer>

      <ArrowSmallScreenContainer>
        <ArrowSmallScreen src="images/quem_somos/flecha_pequena.png" />
      </ArrowSmallScreenContainer>

      <MainContent>
        <FlipContainer>
          <Flipper>
            <Front>{handleItemMissionComplete()}</Front>

            <Back>
              <ContentItemMissionBorder src="images/quem_somos/borda_missao.png" />
              <ContentItemMissionText>
                {getLanguageInfo(currentLanguage, 'aboutus_mission_01')}
                <br />
                {getLanguageInfo(currentLanguage, 'aboutus_mission_02')}
                <br />
                {getLanguageInfo(currentLanguage, 'aboutus_mission_03')}
                <br />
                {getLanguageInfo(currentLanguage, 'aboutus_mission_04')}
                <br />
                {getLanguageInfo(currentLanguage, 'aboutus_mission_05')}
              </ContentItemMissionText>
            </Back>
          </Flipper>
        </FlipContainer>

        <FlipContainer>
          <Flipper>
            <Front>{handleItemVisionComplete()}</Front>

            <Back>
              <ContentItemVisionBorder src="images/quem_somos/borda_visao.png" />
              <ContentItemVisionText>
                {getLanguageInfo(currentLanguage, 'aboutus_vision_01')}
                <br />
                {getLanguageInfo(currentLanguage, 'aboutus_vision_02')}
                <br />
                {getLanguageInfo(currentLanguage, 'aboutus_vision_03')}
              </ContentItemVisionText>
            </Back>
          </Flipper>
        </FlipContainer>

        <FlipContainer>
          <Flipper>
            <Front>{handleItemValuesComplete()}</Front>

            <Back>
              <ContentItemValuesBorder src="images/quem_somos/borda_valores.png" />
              <ContentItemValuesText>
                {getLanguageInfo(currentLanguage, 'aboutus_values_01')}
                <br />
                {getLanguageInfo(currentLanguage, 'aboutus_values_02')}
                <br />
                {getLanguageInfo(currentLanguage, 'aboutus_values_03')}
              </ContentItemValuesText>
            </Back>
          </Flipper>
        </FlipContainer>
      </MainContent>
      <ExtraInfoTopLine>
        {getLanguageInfo(currentLanguage, 'aboutus_extra_info_01')}
      </ExtraInfoTopLine>
      <ExtraInfoBottomLine>
        {getLanguageInfo(currentLanguage, 'aboutus_extra_info_02')}

        <ExtraInfoHighLight>
          {getLanguageInfo(currentLanguage, 'aboutus_extra_info_03')}
        </ExtraInfoHighLight>

        {getLanguageInfo(currentLanguage, 'aboutus_extra_info_04')}

        <ExtraInfoHighLight>
          {getLanguageInfo(currentLanguage, 'aboutus_extra_info_05')}
        </ExtraInfoHighLight>

        {getLanguageInfo(currentLanguage, 'aboutus_extra_info_06')}
      </ExtraInfoBottomLine>
      <Separator />
    </MainContainer>
  );
};

export default AboutUs;
