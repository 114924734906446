import styled, { css } from 'styled-components';
import { Link } from 'react-scroll';

export const MainContainer = styled.main`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${theme.colors.white};
  `}
`;

export const Title = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin-top: 4rem;
    text-align: center;
    color: ${theme.colors.veryDarkBlue};
    font-weight: bold;
    font-size: ${theme.fontSizes.xxxxLarge};
  `}
`;

export const CertImageContent = styled.div`
  display: flex;
  justify-content: center;
  margin: 4rem 0 4rem 0;

  @media (max-width: 1150px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const CertImageWrapper = styled(Link)`
  width: 420px;
  max-width: 420px;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  margin: 0 4rem 0 4rem;
  border-radius: 20px;
  box-shadow: 4px 3px 10px 5px rgba(0, 0, 0, 0.2);

  @media (max-width: 1150px) {
    margin-bottom: 5rem;
  }

  @media (max-width: 950px) {
    width: 90%;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1150px;
  display: flex;
  justify-content: center;
  margin: 2rem 0 2rem 0;
  padding: 2rem;
  border-radius: 20px;
  box-shadow: 4px 3px 10px 5px rgba(0, 0, 0, 0.2);

  @media (max-width: 1170px) {
    width: 90%;
  }

  @media (max-width: 920px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const InfoContent = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 2rem 0 2rem;
    text-align: justify;
    text-indent: 5rem;
    line-height: 2;
    color: ${theme.colors.veryDarkBlue};
    font-size: ${theme.fontSizes.meddium};
    font-weight: 500;

    @media (max-width: 920px) {
      > div {
        margin-top: 2rem;
      }
    }
  `}
`;

export const InfoContentLargeScreen = styled(InfoContent)`
  @media (max-width: 920px) {
    display: none;
  }
`;

export const InfoContentSmallScreen = styled(InfoContent)`
  @media (min-width: 921px) {
    display: none;
  }
`;

export const Image = styled.img`
  width: 30rem;
`;
