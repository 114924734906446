import React from 'react';

import Separator from '~/components/Separator';
import OurStoryImageMultiCarousel from '~/components/OurStoryImageMultiCarousel';

import { useAppLanguage } from '~/hooks/language';
import { getLanguageInfo } from '~/utils';

import {
  MainContainer,
  Title,
  ContentContainer,
  ContentTitle,
  ContentWrapper,
  ContentIconWrapper,
  ContentTextWrapper,
  ContentIcon,
  ContentText,
  ContentFooter,
} from './styles';

const OurStory = (): JSX.Element => {
  const { currentLanguage } = useAppLanguage();

  return (
    <MainContainer id="ourstory">
      <Title>{getLanguageInfo(currentLanguage, 'ourstory_title')}</Title>

      <OurStoryImageMultiCarousel />

      <ContentContainer>
        <ContentTitle>
          {getLanguageInfo(
            currentLanguage,
            'ourstory_first_section_info_title',
          )}
        </ContentTitle>
        <ContentWrapper>
          <ContentIconWrapper>
            <ContentIcon src="images/nossa_historia/icone_trofeu.png" />
          </ContentIconWrapper>

          <ContentTextWrapper>
            <ContentText>
              {getLanguageInfo(
                currentLanguage,
                'ourstory_first_section_first_info_text',
              )}
            </ContentText>
          </ContentTextWrapper>

          <ContentTextWrapper>
            <ContentText>
              {getLanguageInfo(
                currentLanguage,
                'ourstory_first_section_second_info_text',
              )}
            </ContentText>
          </ContentTextWrapper>
        </ContentWrapper>

        <ContentTitle>
          {getLanguageInfo(
            currentLanguage,
            'ourstory_second_section_info_title',
          )}
        </ContentTitle>
        <ContentWrapper>
          <ContentIconWrapper>
            <ContentIcon src="images/nossa_historia/icone_geracao.png" />
          </ContentIconWrapper>
          <ContentTextWrapper>
            <ContentText>
              {getLanguageInfo(
                currentLanguage,
                'ourstory_second_section_first_info_text',
              )}
            </ContentText>
          </ContentTextWrapper>

          <ContentTextWrapper>
            <ContentText>
              {getLanguageInfo(
                currentLanguage,
                'ourstory_second_section_second_info_text_01',
              )}
            </ContentText>

            <ContentText>
              {getLanguageInfo(
                currentLanguage,
                'ourstory_second_section_second_info_text_02',
              )}
            </ContentText>
          </ContentTextWrapper>
        </ContentWrapper>

        <ContentTitle>
          {getLanguageInfo(
            currentLanguage,
            'ourstory_third_section_info_title',
          )}
        </ContentTitle>
        <ContentWrapper>
          <ContentIconWrapper>
            <ContentIcon src="images/nossa_historia/icone_evolucao.png" />
          </ContentIconWrapper>

          <ContentTextWrapper>
            <ContentText>
              {getLanguageInfo(
                currentLanguage,
                'ourstory_third_section_first_info_text',
              )}
            </ContentText>
          </ContentTextWrapper>

          <ContentTextWrapper>
            <ContentText>
              {getLanguageInfo(
                currentLanguage,
                'ourstory_third_section_second_info_text',
              )}
            </ContentText>
          </ContentTextWrapper>
        </ContentWrapper>

        <ContentFooter>
          {getLanguageInfo(currentLanguage, 'ourstory_footer')}
        </ContentFooter>
      </ContentContainer>
      <Separator />
    </MainContainer>
  );
};

export default OurStory;
