import React from 'react';
import ReactGA from 'react-ga';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import PrivacyBar from '../../components/PrivacyBar';
import Home from './Home';
import AboutUs from './AboutUs';
import OurStory from './OurStory';
import Production from './Production';
import Certifications from './Certifications';
import PeopleManagement from './PeopleManagement';
import Contact from './Contact';

const Dashboard = (): JSX.Element => {
  ReactGA.pageview(window.location.href);

  return (
    <>
      <Header />
      <Home />
      <AboutUs />
      <OurStory />
      <Production />
      <Certifications />
      <PeopleManagement />
      <Contact />
      <Footer />
      <PrivacyBar />
    </>
  );
};

export default Dashboard;
