import React from 'react';

import LanguageDropdown from './LanguageDropdown';

import { Container } from './styles';

const ExtrasNavbar = (): JSX.Element => {
  return (
    <>
      <Container>
        <LanguageDropdown />
      </Container>
    </>
  );
};

export default ExtrasNavbar;
