import React, { InputHTMLAttributes, useState, useCallback } from 'react';
import { FiAlertOctagon } from 'react-icons/fi';

import { Container, TextAreaContainer, Label, Text, Error } from './styles';

interface ITextAreaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  labelFor?: string;
  labelText?: string;
  showLabel?: boolean;
  error?: string;
}

const TextArea = React.forwardRef<HTMLTextAreaElement, ITextAreaProps>(
  (
    { name, labelText, labelFor, showLabel, error, ...rest }: ITextAreaProps,
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleInputFocus = useCallback(() => {
      setIsFocused(!isFocused);
    }, [isFocused]);

    const handleInputBlur = useCallback(() => {
      setIsFocused(false);
    }, []);

    return (
      <Container>
        {showLabel && <Label isFocused={isFocused}>{labelText}</Label>}

        <TextAreaContainer
          id={labelFor}
          isFocused={isFocused}
          isErrored={!!error}
        >
          <Text
            name={name}
            type="type"
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            cols={5}
            rows={5}
            ref={ref}
            {...rest}
          />
        </TextAreaContainer>
        {error && (
          <Error title={error}>
            <FiAlertOctagon color="#c91918" size={20} />
          </Error>
        )}
      </Container>
    );
  },
);

export default TextArea;
