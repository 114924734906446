import styled, { css } from 'styled-components';
import { shade } from 'polished';
import { Link } from 'react-router-dom';

import Button from '~/components/Button';

interface IFormInputProps {
  isHidden?: boolean;
}

interface IStyledButtonProps {
  isDisabled?: boolean;
}

export const MainContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    background: ${theme.colors.white};
  `}
`;

export const Title = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin-top: 4rem;
    text-align: center;
    color: ${theme.colors.veryDarkBlue};
    font-weight: bold;
    font-size: ${theme.fontSizes.xxxxLarge};
  `}
`;

export const Subtitle = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin-top: 1.5rem;
    text-align: center;
    color: ${theme.colors.veryDarkBlue};
    font-size: ${theme.fontSizes.large};
  `}
`;

export const SubtitleHighLight = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.strongRed};
  `}
`;

export const MainContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const LogoWrapper = styled.div`
  width: 30%;
  justify-content: center;

  @media (max-width: 1100px) {
    width: 100%;
  }
`;

export const LogoInfo = styled.div`
  ${({ theme }) => css`
    width: 100%;
    text-align: center;
    line-height: 2.4;
    color: ${theme.colors.veryDarkBlue};
    font-size: ${theme.fontSizes.large};
  `}
`;

export const LogoImageWrapper = styled.div`
  text-align: center;
`;

export const LogoImage = styled.img`
  width: 170px;
`;

export const FormWrapper = styled.div`
  width: 40%;
  display: flex;
  justify-content: center;

  @media (max-width: 1100px) {
    width: 100%;
  }
`;

export const FormInput = styled.div<IFormInputProps>`
  ${({ isHidden }) => css`
    display: flex;
    justify-content: flex-start;
    width: 45rem;

    ${isHidden &&
    css`
      display: none;
    `}

    @media screen and (max-width: 500px) {
      width: 35rem;
    }
  `}
`;

export const FormSelect = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 45rem;

  @media screen and (max-width: 500px) {
    width: 35rem;
  }
`;

export const FileContainer = styled.div`
  width: 45rem;
  display: flex;
  padding: 10px;

  @media screen and (max-width: 500px) {
    width: 35rem;
  }
`;

export const FileButtonWrapper = styled.div`
  width: 15.5rem;
`;

export const FileButton = styled.label`
  ${({ theme }) => css`
    padding: 5px 20px 5px 20px;
    background: ${theme.colors.lightGrey};
    color: #000;
    font-size: ${theme.fontSizes.meddium};
    border: 1px solid ${theme.colors.grey};
    border-radius: ${theme.radius.small};
  `}
`;

export const FileInfoWrapper = styled.div`
  padding-top: 5px;
`;

export const FileInfo = styled.label`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.small};
  `}
`;

export const AgreementContainer = styled.div`
  width: 45rem;
  display: flex;
  align-items: center;

  @media screen and (max-width: 500px) {
    width: 30rem;
  }
`;

export const AgreementInfo = styled.div`
  ${({ theme }) => css`
    padding-top: 0.3rem;
    padding-left: 0.5rem;
    color: ${theme.colors.grey};
    font-size: ${theme.fontSizes.meddium};
  `}
`;

export const AgreementInfoPrivacyPolicyLink = styled(Link)`
  ${({ theme }) => css`
    color: ${theme.colors.veryDarkBlue};
    font-size: ${theme.fontSizes.meddium};
    text-decoration: underline;
  `}
`;

export const ButtonContainer = styled.div`
  width: 45rem;
  display: flex;
  padding: 1rem 3rem 3rem 0rem;
  justify-content: right;

  @media screen and (max-width: 500px) {
    width: 35rem;
  }
`;

export const StyledButton = styled(Button)<IStyledButtonProps>`
  ${({ theme, isDisabled }) => css`
    background-color: ${theme.colors.strongRed};

    :hover {
      background: ${shade(0.2, theme.colors.strongRed)};
    }

    ${isDisabled &&
    css`
      cursor: not-allowed;
    `}
  `}
`;

export const ExtraWrapper = styled.div`
  width: 30%;

  @media (max-width: 1100px) {
    width: 0;
  }
`;
