import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';

import App from './App';
import { AppLanguageProvider } from './hooks/language';
import { AppCookiesProvider } from './hooks/cookies';
import { AppThemeProvider } from './hooks/theme';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <AppLanguageProvider>
      <AppCookiesProvider>
        <AppThemeProvider>
          <App />
        </AppThemeProvider>
      </AppCookiesProvider>
    </AppLanguageProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();

ReactGA.initialize(String(process.env.REACT_APP_G_A_ID));
