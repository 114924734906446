import React, { useState } from 'react';

import { AnimatePresence } from 'framer-motion';

import { Container, AnimatedDropdown, NavButton } from './styles';
import { DROP_DOWN_ANIMATION } from './animations';

import { useAppLanguage } from '~/hooks/language';

const LanguageDropdown: React.FC = () => {
  const { currentLanguage, handleLanguageChange } = useAppLanguage();
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const handleDropdownVisibility = (): void => {
    if (isDropdownVisible) {
      setIsDropdownVisible(false);
    } else {
      setIsDropdownVisible(true);
    }
  };

  function getLanguage(): string {
    if (currentLanguage === 'english') {
      return 'English';
    }
    if (currentLanguage === 'spanish') {
      return 'Español';
    }

    return 'Português';
  }

  function handlePortugueseNavButton(): React.ReactNode {
    if (currentLanguage !== 'portuguese') {
      return (
        <NavButton
          onClick={() => {
            handleLanguageChange('portuguese');
          }}
        >
          Português
        </NavButton>
      );
    }

    return <></>;
  }

  function handleEnglishNavButton(): React.ReactNode {
    if (currentLanguage !== 'english') {
      return (
        <NavButton
          onClick={() => {
            handleLanguageChange('english');
          }}
        >
          English
        </NavButton>
      );
    }

    return <></>;
  }

  function handleSpanishNavButton(): React.ReactNode {
    if (currentLanguage !== 'spanish') {
      return (
        <NavButton
          onClick={() => {
            handleLanguageChange('spanish');
          }}
        >
          Español
        </NavButton>
      );
    }

    return <></>;
  }

  return (
    <Container onClick={handleDropdownVisibility}>
      <div>{getLanguage()}</div>
      <AnimatePresence>
        {isDropdownVisible && (
          <AnimatedDropdown
            variants={DROP_DOWN_ANIMATION}
            initial="hidden"
            animate="visible"
            exit="hidden"
          >
            <ul>
              {handlePortugueseNavButton()}
              {handleEnglishNavButton()}
              {handleSpanishNavButton()}
            </ul>
          </AnimatedDropdown>
        )}
      </AnimatePresence>
    </Container>
  );
};

export default LanguageDropdown;
