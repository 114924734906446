import React from 'react';

import ExtrasNavbar from '../ExtrasNavbar';
import NavbarNewTab from '../NavbarNewTab';

import { Container } from './styles';

const HeaderNewTab = (): JSX.Element => {
  return (
    <Container>
      <ExtrasNavbar />
      <NavbarNewTab />
    </Container>
  );
};

export default HeaderNewTab;
