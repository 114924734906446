export default {
  colors: {
    primary: '#288CC2',
    primaryDark: '#FF7A00',
    secondary: '#8eacd5',
    primaryBackground: '#fff',
    secondaryBackground: '#dcdcdc',
    menuBackground: '#24234e',
    primaryLabel: '#288CC2',
    secondaryLabel: '#8C91A5',
    textLabel: '#000000',
    label: '#000000',
    labelHover: '#fff',
    buttonBackgroundHover: '#375e93',
    white: '#fff',
    lightGrey: '#dfdfdf',
    veryLightGrey: '#fcfcfc',
    grey: '#8C91A5',
    greyLowerOpacity: '#abaebf',
    darkGrey: '#78828a',
    veryDarkGrey: '#202020',
    veryDarkBlue: '#24234e',
    veryDarkDesaturatedBlue: '#1b1b3f',
    red: '#ff0000',
    strongRed: '#c91918',
    brightOrange: '#f6b62c',
    green: '#008800',
    success: '#20C05C',
    error: '#FD4F48',
    background: '#fff',
  },
  fontSizes: {
    tiny: '1rem',
    small: '1.2rem',
    meddiumsmall: '1.3rem',
    default: '1.4rem',
    meddium: '1.5rem',
    large: '1.6rem',
    xLarge: '1.8rem',
    xxLarge: '2.0rem',
    xxxLarge: '2.8rem',
    xxxxLarge: '3.6rem',
  },
  spacing: {
    default: '1.6rem',
    vertical: '1.6rem 0',
    horizontal: '0 1.6rem',
    large: '2.4rem',
  },
  transition: {
    default: '180ms ease-in-out',
  },
  radius: {
    xxxxxLarge: '2.0rem',
    xxxxLarge: '1.8rem',
    xxxLarge: '1.6rem',
    xxLarge: '1.4rem',
    xLarge: '1.2rem',
    large: '1rem',
    meddium: '0.8rem',
    default: '0.6rem',
    small: '0.4rem',
    tiny: '0.2rem',
  },
  shadows: {
    default: '0 0.5rem 2rem rgba(0, 0, 0, 0.08)',
    meddium: '0 0.5rem 2rem rgba(0, 0, 0, 0.3)',
    flat: '0 0.2rem 0.2rem rgba(0, 0, 0, 0.08)',
  },
};
