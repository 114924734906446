import React, { useState } from 'react';
import { FaTimes, FaBars } from 'react-icons/fa';

import { useAppLanguage } from '~/hooks/language';
import { getLanguageInfo } from '~/utils';

import {
  NavBar,
  Container,
  LogoWrapper,
  Logo,
  Menu,
  Item,
  ItemLink,
  Icon,
} from './styles';

const NavbarNewTab = (): JSX.Element => {
  const { currentLanguage } = useAppLanguage();
  const [click, setClick] = useState(false);

  const handleClick = (): void => setClick(!click);

  const closeMobileMenu = (): void => setClick(false);

  return (
    <>
      <NavBar>
        <Container>
          <LogoWrapper>
            <Logo src="icons/logo_tipo_superior.png" />
          </LogoWrapper>

          <Icon onClick={handleClick}>{click ? <FaTimes /> : <FaBars />}</Icon>

          <Menu isActive={click}>
            <Item>
              <ItemLink
                to={{
                  pathname: `/#home`,
                }}
                target="_blank"
                rel="noopener noreferrer"
                onClick={closeMobileMenu}
              >
                {getLanguageInfo(currentLanguage, 'nav_home')?.toUpperCase()}
              </ItemLink>
            </Item>

            <Item>
              <ItemLink
                to={{
                  pathname: `/#aboutus`,
                }}
                target="_blank"
                rel="noopener noreferrer"
                onClick={closeMobileMenu}
              >
                {getLanguageInfo(
                  currentLanguage,
                  'nav_about_us',
                )?.toUpperCase()}
              </ItemLink>
            </Item>

            <Item>
              <ItemLink
                to={{
                  pathname: `/#ourstory`,
                }}
                target="_blank"
                rel="noopener noreferrer"
                onClick={closeMobileMenu}
              >
                {getLanguageInfo(
                  currentLanguage,
                  'nav_our_story',
                )?.toUpperCase()}
              </ItemLink>
            </Item>

            <Item>
              <ItemLink
                to={{
                  pathname: `/#production`,
                }}
                target="_blank"
                rel="noopener noreferrer"
                onClick={closeMobileMenu}
              >
                {getLanguageInfo(
                  currentLanguage,
                  'nav_production',
                )?.toUpperCase()}
              </ItemLink>
            </Item>

            <Item>
              <ItemLink
                to={{
                  pathname: `/#certifications`,
                }}
                target="_blank"
                rel="noopener noreferrer"
                onClick={closeMobileMenu}
              >
                {getLanguageInfo(
                  currentLanguage,
                  'nav_certifications',
                )?.toUpperCase()}
              </ItemLink>
            </Item>

            <Item>
              <ItemLink
                to={{
                  pathname: `/#peoplemanagement`,
                }}
                target="_blank"
                rel="noopener noreferrer"
                onClick={closeMobileMenu}
              >
                {getLanguageInfo(
                  currentLanguage,
                  'nav_people_management',
                )?.toUpperCase()}
              </ItemLink>
            </Item>

            <Item>
              <ItemLink
                to={{
                  pathname: `/#contact`,
                }}
                target="_blank"
                rel="noopener noreferrer"
                onClick={closeMobileMenu}
              >
                {getLanguageInfo(currentLanguage, 'nav_contact')?.toUpperCase()}
              </ItemLink>
            </Item>
          </Menu>
        </Container>
      </NavBar>
    </>
  );
};

export default NavbarNewTab;
