import styled, { css } from 'styled-components';
import Switch from 'react-switch';

interface ILabelProps {
  isDisabled: boolean;
}

export const Container = styled.div`
  margin: 1rem 3rem;
  display: flex;
  align-items: center;
`;

export const ToggleButton = styled(Switch)`
  ${({ theme }) => css`
    border: 0;
    border-radius: ${theme.radius.small};
    color: ${theme.colors.grey};
  `};
`;

export const Label = styled.div<ILabelProps>`
  ${({ theme, isDisabled }) => css`
    font-size: ${theme.fontSizes.small};
    margin-left: 0.5rem;
    font-weight: bold;
    color: ${theme.colors.green};

    ${isDisabled &&
    css`
      color: ${theme.colors.red};
    `}
  `};
`;
