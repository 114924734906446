import styled from 'styled-components';

export const ContainerLargeScreen = styled.div`
  margin-bottom: -1rem;

  @media (max-width: 729px) {
    display: none;
  }
`;

export const ContainerSmallScreen = styled.div`
  margin-bottom: -1rem;

  @media (min-width: 730px) {
    display: none;
  }
`;

export const Banner = styled.div`
  cursor: pointer;
`;

export const Image = styled.img`
  width: 100%;
`;
