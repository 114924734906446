import styled, { css } from 'styled-components';

export const MainContainer = styled.main`
  width: 100%;
  background: linear-gradient(
      to top,
      rgba(0, 17, 62, 0.88),
      rgba(0, 17, 62, 0.88)
    ),
    url('images/nossa_historia/historia_009.png') no-repeat top center;
  height: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const Title = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin-top: 4rem;
    text-align: center;
    color: ${theme.colors.white};
    font-weight: bold;
    font-size: ${theme.fontSizes.xxxxLarge};
  `}
`;

export const ContentContainer = styled.div`
  width: 100%;
  padding: 0 10rem 0 10rem;

  @media (max-width: 615px) {
    padding: 0 2rem 0 2rem;
  }
`;

export const ContentTitle = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin-top: 2rem;
    color: ${theme.colors.strongRed};
    font-weight: bold;
    font-size: ${theme.fontSizes.xxxxLarge};

    @media (max-width: 1024px) {
      margin-top: 5rem;
      text-align: center;
    }
  `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  margin-top: 2rem;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const ContentIconWrapper = styled.div`
  width: 10%;
  text-align: center;

  @media (max-width: 1024px) {
    width: 100%;
    margin-bottom: 2rem;
  }
`;

export const ContentTextWrapper = styled.div`
  width: 40%;
  margin-left: 5rem;

  @media (max-width: 1024px) {
    width: 100%;
    margin-left: 0;
  }
`;

export const ContentIcon = styled.img`
  width: 12rem;
`;

export const ContentText = styled.div`
  ${({ theme }) => css`
    text-align: justify;
    text-indent: 5rem;
    line-height: 2;
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes.default};
  `}
`;

export const ContentFooter = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-align: center;
    color: ${theme.colors.strongRed};
    font-weight: bold;
    font-size: ${theme.fontSizes.xxxxLarge};

    @media (max-width: 1024px) {
      margin-top: 5rem;
    }
  `}
`;
