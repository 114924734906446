import React, { useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import PMGalleryModal from '~/components/PMGalleryModal';

import { useAppLanguage } from '~/hooks/language';
import { getLanguageInfo } from '~/utils';

import {
  Container,
  ContentWrapper,
  FlipContainer,
  Flipper,
  Front,
  Back,
  ContentItemBorder,
  ContentItemImageWrapper,
  ContentItemImage,
  ContentItemText,
} from './styles';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1225 },
    items: 4,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1225, min: 740 },
    items: 2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 740, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const PMGalleryMultiCarousel = (): JSX.Element => {
  const { currentLanguage } = useAppLanguage();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [urlModal, setUrlModal] = useState('');

  function clearModal(): void {
    setTitleModal('');
    setUrlModal('');
  }

  function getTitleModal(type: string): string {
    let title_modal: string | undefined;

    if (type === 'granja_de_recria_01') {
      title_modal = getLanguageInfo(
        currentLanguage,
        'peoplemanagement_gallery_first_item_title',
      );
      if (title_modal) {
        return title_modal;
      }

      return 'Granja de Recria';
    }

    if (type === 'granja_de_recria_02') {
      title_modal = getLanguageInfo(
        currentLanguage,
        'peoplemanagement_gallery_second_item_title',
      );
      if (title_modal) {
        return title_modal;
      }

      return 'Granja de Recria';
    }

    if (type === 'granja_de_recria_03') {
      title_modal = getLanguageInfo(
        currentLanguage,
        'peoplemanagement_gallery_third_item_title',
      );
      if (title_modal) {
        return title_modal;
      }

      return 'Granja de Recria';
    }

    if (type === 'incubatorio_04') {
      title_modal = getLanguageInfo(
        currentLanguage,
        'peoplemanagement_gallery_fourth_item_title',
      );
      if (title_modal) {
        return title_modal;
      }

      return 'Incubatório';
    }

    return '';
  }

  return (
    <>
      <Container>
        <Carousel
          swipeable={false}
          draggable={false}
          showDots
          responsive={responsive}
          infinite
          autoPlay
          autoPlaySpeed={3000}
          customTransition="all .5"
          containerClass="carousel-container"
          removeArrowOnDeviceType={['desktop', 'tablet', 'mobile']}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          <ContentWrapper>
            <FlipContainer
              onClick={() => {
                setIsOpenModal(true);
                setTitleModal(getTitleModal('granja_de_recria_01'));
                setUrlModal(
                  'images/gestao_de_pessoas/galeria/granja_de_recria_01.jpeg',
                );
              }}
            >
              <Flipper>
                <Front>
                  <ContentItemImageWrapper>
                    <ContentItemImage src="images/gestao_de_pessoas/galeria/granja_de_recria_01.jpeg" />
                  </ContentItemImageWrapper>
                </Front>
                <Back>
                  <ContentItemBorder src="images/gestao_de_pessoas/galeria/borda.png" />
                  <ContentItemText>
                    {getLanguageInfo(
                      currentLanguage,
                      'peoplemanagement_gallery_first_item_title',
                    )}
                  </ContentItemText>
                </Back>
              </Flipper>
            </FlipContainer>
          </ContentWrapper>

          <ContentWrapper>
            <FlipContainer
              onClick={() => {
                setIsOpenModal(true);
                setTitleModal(getTitleModal('granja_de_recria_02'));
                setUrlModal(
                  'images/gestao_de_pessoas/galeria/granja_de_recria_02.jpeg',
                );
              }}
            >
              <Flipper>
                <Front>
                  <ContentItemImageWrapper>
                    <ContentItemImage src="images/gestao_de_pessoas/galeria/granja_de_recria_02.jpeg" />
                  </ContentItemImageWrapper>
                </Front>
                <Back>
                  <ContentItemBorder src="images/gestao_de_pessoas/galeria/borda.png" />
                  <ContentItemText>
                    {getLanguageInfo(
                      currentLanguage,
                      'peoplemanagement_gallery_second_item_title',
                    )}
                  </ContentItemText>
                </Back>
              </Flipper>
            </FlipContainer>
          </ContentWrapper>

          <ContentWrapper>
            <FlipContainer
              onClick={() => {
                setIsOpenModal(true);
                setTitleModal(getTitleModal('granja_de_recria_03'));
                setUrlModal(
                  'images/gestao_de_pessoas/galeria/granja_de_recria_03.jpeg',
                );
              }}
            >
              <Flipper>
                <Front>
                  <ContentItemImageWrapper>
                    <ContentItemImage src="images/gestao_de_pessoas/galeria/granja_de_recria_03.jpeg" />
                  </ContentItemImageWrapper>
                </Front>
                <Back>
                  <ContentItemBorder src="images/gestao_de_pessoas/galeria/borda.png" />
                  <ContentItemText>
                    {getLanguageInfo(
                      currentLanguage,
                      'peoplemanagement_gallery_third_item_title',
                    )}
                  </ContentItemText>
                </Back>
              </Flipper>
            </FlipContainer>
          </ContentWrapper>

          <ContentWrapper>
            <FlipContainer
              onClick={() => {
                setIsOpenModal(true);
                setTitleModal(getTitleModal('incubatorio_04'));
                setUrlModal('images/gestao_de_pessoas/galeria/incubatorio.jpg');
              }}
            >
              <Flipper>
                <Front>
                  <ContentItemImageWrapper>
                    <ContentItemImage src="images/gestao_de_pessoas/galeria/incubatorio.jpg" />
                  </ContentItemImageWrapper>
                </Front>
                <Back>
                  <ContentItemBorder src="images/gestao_de_pessoas/galeria/borda.png" />
                  <ContentItemText>
                    {getLanguageInfo(
                      currentLanguage,
                      'peoplemanagement_gallery_fourth_item_title',
                    )}
                  </ContentItemText>
                </Back>
              </Flipper>
            </FlipContainer>
          </ContentWrapper>
        </Carousel>

        <PMGalleryModal
          isOpen={isOpenModal}
          title={titleModal}
          url={urlModal}
          setIsOpen={setIsOpenModal}
          onClose={() => {
            clearModal();
          }}
        />
      </Container>
    </>
  );
};

export default PMGalleryMultiCarousel;
