import React, { useState, useEffect, useCallback } from 'react';

import Input from '~/components/Input';
import PhoneInput from '~/components/PhoneInput';
import SelectFilter from '~/components/SelectFilter';
import TextArea from '~/components/TextArea';
import Separator from '~/components/Separator';

import { useToast } from '~/hooks/toast';
import { useAuth } from '~/hooks/auth';
import { useAppLanguage } from '~/hooks/language';
import { getLanguageInfo } from '~/utils';

import api from '~/services/api';

import {
  MainContainer,
  Title,
  Subtitle,
  SubtitleHighLight,
  MainContent,
  LogoWrapper,
  LogoInfo,
  LogoImageWrapper,
  LogoImage,
  FormWrapper,
  FormInput,
  FormSelect,
  FileContainer,
  FileButtonWrapper,
  FileButton,
  FileInfoWrapper,
  FileInfo,
  ExtraWrapper,
  ButtonContainer,
  AgreementContainer,
  AgreementInfo,
  AgreementInfoPrivacyPolicyLink,
  StyledButton,
} from './styles';

const Contact = (): JSX.Element => {
  const { addToast } = useToast();
  const { setToken, removeToken } = useAuth();
  const { currentLanguage } = useAppLanguage();
  const [name, setName] = useState('');
  const [telephone, setTelephone] = useState('');
  const [email, setEmail] = useState('');
  const [departmentValue, setDepartmentValue] = useState('');
  const [departmentLabel, setDepartmentLabel] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [toEmailError, setToEmailError] = useState('');
  const [messageError, setMessageError] = useState('');
  const [sendClicked, setSendClicked] = useState(false);
  const [agreementChecked, setAgreementChecked] = useState(false);
  const [errorFieldMessage, setErrorFieldMessage] =
    useState('Campo obrigatório');
  const [file, setFile] = useState<File>();

  useEffect(() => {
    if (departmentValue === '') {
      const default_label = getLanguageInfo(
        currentLanguage,
        'contact_form_to_email_op_default',
      );

      if (default_label) {
        setDepartmentLabel(default_label);
      }
    }

    const field_error_message = getLanguageInfo(
      currentLanguage,
      'contact_form_field_error_message',
    );

    if (field_error_message) {
      setErrorFieldMessage(field_error_message);
    }
  }, [currentLanguage, departmentValue]);

  useEffect(() => {
    if (errorFieldMessage && errorFieldMessage !== '') {
      if (nameError !== '') {
        setNameError(errorFieldMessage);
      }

      if (emailError !== '') {
        setEmailError(errorFieldMessage);
      }

      if (toEmailError !== '') {
        setToEmailError(errorFieldMessage);
      }

      if (messageError !== '') {
        setMessageError(errorFieldMessage);
      }
    }
  }, [errorFieldMessage, nameError, emailError, toEmailError, messageError]);

  const getToastSuccessTitle = useCallback(async (): Promise<string> => {
    const success_title = getLanguageInfo(
      currentLanguage,
      'contact_form_success_title',
    );

    if (success_title) {
      return success_title;
    }

    return 'Sucesso!';
  }, [currentLanguage]);

  const getToastSuccessDescription = useCallback(async (): Promise<string> => {
    const success_description = getLanguageInfo(
      currentLanguage,
      'contact_form_success_description',
    );

    if (success_description) {
      return success_description;
    }

    return 'Mensagem enviada com sucesso';
  }, [currentLanguage]);

  const getToastErrorTitle = useCallback(async (): Promise<string> => {
    const error_title = getLanguageInfo(
      currentLanguage,
      'contact_form_error_title',
    );

    if (error_title) {
      return error_title;
    }

    return 'Ocorreu um erro!';
  }, [currentLanguage]);

  const getToastErrorDescription = useCallback(async (): Promise<string> => {
    const error_description = getLanguageInfo(
      currentLanguage,
      'contact_form_error_description',
    );

    if (error_description) {
      return error_description;
    }

    return 'Não foi possível enviar a mensagem';
  }, [currentLanguage]);

  const getToastInvalidFieldsTitle = useCallback(async (): Promise<string> => {
    const invalid_fields_title = getLanguageInfo(
      currentLanguage,
      'contact_form_invalid_fields_title',
    );

    if (invalid_fields_title) {
      return invalid_fields_title;
    }

    return 'Campos Inválidos!';
  }, [currentLanguage]);

  const getToastInvalidFieldsDescription =
    useCallback(async (): Promise<string> => {
      const invalid_fields_description = getLanguageInfo(
        currentLanguage,
        'contact_form_invalid_fields_description',
      );

      if (invalid_fields_description) {
        return invalid_fields_description;
      }

      return 'Necessário preencher/selecionar todos os campos';
    }, [currentLanguage]);

  const sendSuccessToast = useCallback(async () => {
    addToast({
      type: 'success',
      title: await getToastSuccessTitle(),
      description: await getToastSuccessDescription(),
    });
  }, [addToast, getToastSuccessTitle, getToastSuccessDescription]);

  const sendErrorToast = useCallback(async () => {
    addToast({
      type: 'error',
      title: await getToastErrorTitle(),
      description: await getToastErrorDescription(),
    });
  }, [addToast, getToastErrorTitle, getToastErrorDescription]);

  const sendInvalidFieldsToast = useCallback(async () => {
    addToast({
      type: 'error',
      title: await getToastInvalidFieldsTitle(),
      description: await getToastInvalidFieldsDescription(),
    });
  }, [addToast, getToastInvalidFieldsTitle, getToastInvalidFieldsDescription]);

  const submitForm = useCallback(
    async (e: any) => {
      e.preventDefault();
      let valid = true;
      setSendClicked(true);
      if (name === '') {
        setNameError(errorFieldMessage);
        valid = false;
      }
      if (email === '') {
        setEmailError(errorFieldMessage);
        valid = false;
      }
      if (departmentValue === '') {
        setToEmailError(errorFieldMessage);
        valid = false;
      }
      if (message === '') {
        setMessageError(errorFieldMessage);
        valid = false;
      }

      if (valid) {
        const data = new FormData();
        data.append('client', 'avicolapb');
        data.append('name', name);
        data.append('telephone', telephone);
        data.append('email', email);
        data.append('to_email', departmentValue);
        data.append('subject', subject);
        data.append('message', message);

        if (file) {
          data.append('file', file);
        }

        await setToken();
        try {
          await api.post('/clients/avicolapb', data);

          sendSuccessToast();
          setName('');
          setTelephone('');
          setEmail('');
          setDepartmentValue('');
          setDepartmentLabel('');
          setSubject('');
          setMessage('');
          setNameError('');
          setEmailError('');
          setToEmailError('');
          setMessageError('');
          setFile(undefined);
          setSendClicked(false);
          setAgreementChecked(false);
        } catch (error) {
          sendErrorToast();
        }

        removeToken();
      } else {
        sendInvalidFieldsToast();
      }
    },
    [
      departmentValue,
      email,
      errorFieldMessage,
      file,
      message,
      name,
      subject,
      telephone,
      sendSuccessToast,
      sendErrorToast,
      sendInvalidFieldsToast,
      removeToken,
      setToken,
    ],
  );

  function handleNameChange(valueReceived: string): void {
    setName(valueReceived);

    if (sendClicked) {
      if (valueReceived === '') {
        setNameError(errorFieldMessage);
      } else {
        setNameError('');
      }
    }
  }

  function handleTelephoneChange(valueReceived: string): void {
    setTelephone(valueReceived);
  }

  function handleEmailChange(valueReceived: string): void {
    setEmail(valueReceived);

    if (sendClicked) {
      if (valueReceived === '') {
        setEmailError(errorFieldMessage);
      } else {
        setEmailError('');
      }
    }
  }

  function handleDepartmentChange(
    valueReceived: string,
    labelReceived: string,
  ): void {
    setDepartmentValue(valueReceived);
    setDepartmentLabel(labelReceived);
    setSubject(labelReceived);

    if (sendClicked) {
      if (valueReceived === '') {
        setToEmailError(errorFieldMessage);
      } else {
        setToEmailError('');
      }
    }
  }

  function handleMessageChange(valueReceived: string): void {
    setMessage(valueReceived);

    if (sendClicked) {
      if (valueReceived === '') {
        setMessageError(errorFieldMessage);
      } else {
        setMessageError('');
      }
    }
  }

  function handleFiles(event: any): void {
    if (event) {
      if (event.target) {
        if (event.target.files) {
          const newFile = event.target.files[0];
          setFile(newFile);
        }
      }
    }
  }

  function getFileInfo(): string {
    if (file) {
      if (file.name.length > 35) {
        return `${file.name.substring(0, 35)}...`;
      }

      return file.name;
    }

    const file_info = getLanguageInfo(
      currentLanguage,
      'contact_form_file_info',
    );

    if (file_info) {
      return file_info;
    }

    return 'Nenhum arquivo selecionado';
  }

  return (
    <MainContainer id="contact">
      <Title>{getLanguageInfo(currentLanguage, 'contact_title')}</Title>
      <Subtitle>
        {getLanguageInfo(currentLanguage, 'contact_subtitle_01')}
        <SubtitleHighLight>
          {getLanguageInfo(currentLanguage, 'contact_subtitle_02')}
        </SubtitleHighLight>
      </Subtitle>
      <MainContent>
        <LogoWrapper>
          <LogoInfo>
            {getLanguageInfo(currentLanguage, 'contact_logo_info_01')}
            <br />
            {getLanguageInfo(currentLanguage, 'contact_logo_info_02')}
          </LogoInfo>
          <LogoImageWrapper>
            <LogoImage src="images/contato/simbolo.png" />
          </LogoImageWrapper>
        </LogoWrapper>

        <FormWrapper>
          <form id="contact-form" onSubmit={submitForm}>
            <FormInput>
              <Input
                name="name"
                showLabel={false}
                type="text"
                placeholder={getLanguageInfo(
                  currentLanguage,
                  'contact_form_name',
                )}
                value={name}
                error={nameError}
                maxLength={50}
                onChange={(e: any) => {
                  handleNameChange(e.target.value);
                }}
              />
            </FormInput>

            <FormInput>
              <PhoneInput
                name="telephone"
                showLabel={false}
                type="text"
                placeholder={getLanguageInfo(
                  currentLanguage,
                  'contact_form_telephone',
                )}
                value={telephone}
                onUpdate={(e: any) => {
                  handleTelephoneChange(e.target.value);
                }}
              />
            </FormInput>

            <FormInput>
              <Input
                name="email"
                showLabel={false}
                type="email"
                placeholder={getLanguageInfo(
                  currentLanguage,
                  'contact_form_email',
                )}
                value={email}
                error={emailError}
                maxLength={80}
                onChange={(e: any) => {
                  handleEmailChange(e.target.value);
                }}
              />
            </FormInput>

            <FormSelect>
              <SelectFilter
                name="to_email"
                showLabel={false}
                options={[
                  {
                    value: 'comercial@avicolapb.com.br',
                    label: getLanguageInfo(
                      currentLanguage,
                      'contact_form_to_email_op_01',
                    ),
                  },
                  {
                    value: 'compras@avicolapb.com.br',
                    label: getLanguageInfo(
                      currentLanguage,
                      'contact_form_to_email_op_02',
                    ),
                  },
                  {
                    value: 'privacidade@avicolapb.com.br',
                    label: getLanguageInfo(
                      currentLanguage,
                      'contact_form_to_email_op_03',
                    ),
                  },
                  {
                    value: 'contasapagar@avicolapb.com.br',
                    label: getLanguageInfo(
                      currentLanguage,
                      'contact_form_to_email_op_04',
                    ),
                  },
                  {
                    value: 'recrutamento@avicolapb.com.br',
                    label: getLanguageInfo(
                      currentLanguage,
                      'contact_form_to_email_op_05',
                    ),
                  },
                ]}
                value={{
                  value: departmentValue,
                  label: departmentLabel,
                }}
                error={toEmailError}
                onUpdate={(e: any) => {
                  handleDepartmentChange(e.value, e.label);
                }}
              />
            </FormSelect>

            <FormInput isHidden>
              <Input
                name="subject"
                showLabel={false}
                type="text"
                value={subject}
              />
            </FormInput>

            <FormInput>
              <TextArea
                name="message"
                showLabel={false}
                type="text"
                placeholder={getLanguageInfo(
                  currentLanguage,
                  'contact_form_message',
                )}
                value={message}
                error={messageError}
                maxLength={1000}
                onChange={(e: any) => {
                  handleMessageChange(e.target.value);
                }}
              />
            </FormInput>

            <FileContainer>
              <FileButtonWrapper>
                <FileButton htmlFor="filePicker">
                  {getLanguageInfo(currentLanguage, 'contact_form_file_button')}
                </FileButton>

                <input
                  id="filePicker"
                  type="file"
                  accept=".doc,.docx,application/msword,.pdv,.png,.jpg,.jpeg"
                  onChange={event => handleFiles(event)}
                  style={{ visibility: 'hidden' }}
                />
              </FileButtonWrapper>

              <FileInfoWrapper>
                <FileInfo>{getFileInfo()}</FileInfo>
              </FileInfoWrapper>
            </FileContainer>

            <AgreementContainer>
              <input
                name="agreement"
                type="checkbox"
                onChange={(e: any) => {
                  setAgreementChecked(e.target.checked);
                }}
                checked={agreementChecked}
              />
              <AgreementInfo>
                {getLanguageInfo(currentLanguage, 'contact_agreement_info_01')}
                <AgreementInfoPrivacyPolicyLink
                  to={{
                    pathname: `/politicadeprivacidade`,
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {getLanguageInfo(
                    currentLanguage,
                    'contact_agreement_info_privacy_policy',
                  )}
                </AgreementInfoPrivacyPolicyLink>
                {getLanguageInfo(currentLanguage, 'contact_agreement_info_02')}
              </AgreementInfo>
            </AgreementContainer>

            <ButtonContainer>
              <StyledButton
                type="submit"
                disabled={!agreementChecked}
                isDisabled={!agreementChecked}
              >
                {getLanguageInfo(currentLanguage, 'contact_form_send_button')}
              </StyledButton>
            </ButtonContainer>
          </form>
        </FormWrapper>

        <ExtraWrapper />
      </MainContent>
      <Separator />
    </MainContainer>
  );
};

export default Contact;
