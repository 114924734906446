import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

interface ISelectContainerProps {
  isErrored: boolean;
  isFocused: boolean;
  isBlocked?: boolean;
}

interface ILabelProps {
  isFocused: boolean;
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const SelectContainer = styled.div<ISelectContainerProps>`
  ${({ theme, isFocused, isErrored, isBlocked }) => css`
    width: 98.5%;
    align-items: center;

    > select {
      flex: 1;
      border: 0;
      background: transparent;
      font-size: 1.6rem;
    }

    ${isFocused &&
    css`
      border: 1px solid ${theme.colors.primary};
      color: ${theme.colors.primary};

      > svg {
        color: ${theme.colors.primary};
      }
    `}

    ${isErrored &&
    css`
      > svg {
        color: ${theme.colors.secondary};
      }
    `}

    ${isBlocked &&
    css`
      pointer-events: none;
      touch-action: none;
    `}
  `}
`;

export const Label = styled.label<ILabelProps>`
  ${({ theme, isFocused }) => css`
    width: 0;
    height: 0;
    font-size: ${theme.fontSizes.small};
    white-space: nowrap;

    ${isFocused &&
    css`
      color: ${theme.colors.primary};
    `}
  `}
`;

export const Error = styled(Tooltip)`
  ${({ theme }) => css`
    margin: 0 0 0 8px;

    svg {
      color: ${theme.colors.secondary};
      margin-right: 0;
    }

    span {
      background: ${theme.colors.secondary};
      color: #fff;
    }

    &::before {
      border-color: ${theme.colors.secondary} transparent;
    }
  `}
`;
