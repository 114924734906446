import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import styles from './styles.module.css';

import { useAppLanguage } from '~/hooks/language';

import {
  ContainerLargeScreen,
  ContainerSmallScreen,
  Banner,
  Image,
} from './styles';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const HomeImageMultiCarousel = (): JSX.Element => {
  const { currentLanguage } = useAppLanguage();

  function handleLanguageBanners(): React.ReactNode {
    if (currentLanguage === 'english') {
      return (
        <>
          <ContainerLargeScreen>
            <Carousel
              swipeable={false}
              draggable={false}
              showDots
              responsive={responsive}
              infinite
              autoPlay
              autoPlaySpeed={3000}
              customTransition="all .5"
              containerClass="carousel-container"
              removeArrowOnDeviceType={['tablet', 'mobile']}
              dotListClass={styles.dots}
              itemClass="carousel-item-padding-40-px"
            >
              <Banner>
                <Image src="images/home/d_en_01.png" alt="Banner 01" />
              </Banner>

              <Banner>
                <Image src="images/home/d_en_03.png" alt="Banner 03" />
              </Banner>

              <Banner>
                <Image src="images/home/d_en_04.png" alt="Banner 04" />
              </Banner>
            </Carousel>
          </ContainerLargeScreen>

          <ContainerSmallScreen>
            <Carousel
              swipeable={false}
              draggable={false}
              showDots
              responsive={responsive}
              infinite
              autoPlay
              autoPlaySpeed={3000}
              customTransition="all .5"
              containerClass="carousel-container"
              removeArrowOnDeviceType={['tablet', 'mobile']}
              dotListClass={styles.dots}
              itemClass="carousel-item-padding-40-px"
            >
              <Banner>
                <Image src="images/home/m_en_01.png" alt="Banner 01" />
              </Banner>

              <Banner>
                <Image src="images/home/m_en_03.png" alt="Banner 03" />
              </Banner>

              <Banner>
                <Image src="images/home/m_en_04.png" alt="Banner 04" />
              </Banner>
            </Carousel>
          </ContainerSmallScreen>
        </>
      );
    }

    if (currentLanguage === 'spanish') {
      return (
        <>
          <ContainerLargeScreen>
            <Carousel
              swipeable={false}
              draggable={false}
              showDots
              responsive={responsive}
              infinite
              autoPlay
              autoPlaySpeed={3000}
              customTransition="all .5"
              containerClass="carousel-container"
              removeArrowOnDeviceType={['tablet', 'mobile']}
              dotListClass={styles.dots}
              itemClass="carousel-item-padding-40-px"
            >
              <Banner>
                <Image src="images/home/d_es_01.png" alt="Banner 01" />
              </Banner>

              <Banner>
                <Image src="images/home/d_es_03.png" alt="Banner 03" />
              </Banner>

              <Banner>
                <Image src="images/home/d_es_04.png" alt="Banner 04" />
              </Banner>
            </Carousel>
          </ContainerLargeScreen>

          <ContainerSmallScreen>
            <Carousel
              swipeable={false}
              draggable={false}
              showDots
              responsive={responsive}
              infinite
              autoPlay
              autoPlaySpeed={3000}
              customTransition="all .5"
              containerClass="carousel-container"
              removeArrowOnDeviceType={['tablet', 'mobile']}
              dotListClass={styles.dots}
              itemClass="carousel-item-padding-40-px"
            >
              <Banner>
                <Image src="images/home/m_es_01.png" alt="Banner 01" />
              </Banner>

              <Banner>
                <Image src="images/home/m_es_03.png" alt="Banner 03" />
              </Banner>

              <Banner>
                <Image src="images/home/m_es_04.png" alt="Banner 04" />
              </Banner>
            </Carousel>
          </ContainerSmallScreen>
        </>
      );
    }

    return (
      <>
        <ContainerLargeScreen>
          <Carousel
            swipeable={false}
            draggable={false}
            showDots
            responsive={responsive}
            infinite
            autoPlay
            autoPlaySpeed={3000}
            customTransition="all .5"
            containerClass="carousel-container"
            removeArrowOnDeviceType={['tablet', 'mobile']}
            dotListClass={styles.dots}
            itemClass="carousel-item-padding-40-px"
          >
            <Banner>
              <Image src="images/home/d_pt_01.png" alt="Banner 01" />
            </Banner>

            <Banner>
              <Image src="images/home/d_pt_03.png" alt="Banner 03" />
            </Banner>

            <Banner>
              <Image src="images/home/d_pt_04.png" alt="Banner 04" />
            </Banner>
          </Carousel>
        </ContainerLargeScreen>

        <ContainerSmallScreen>
          <Carousel
            swipeable={false}
            draggable={false}
            showDots
            responsive={responsive}
            infinite
            autoPlay
            autoPlaySpeed={3000}
            customTransition="all .5"
            containerClass="carousel-container"
            removeArrowOnDeviceType={['tablet', 'mobile']}
            dotListClass={styles.dots}
            itemClass="carousel-item-padding-40-px"
          >
            <Banner>
              <Image src="images/home/m_pt_01.png" alt="Banner 01" />
            </Banner>

            <Banner>
              <Image src="images/home/m_pt_03.png" alt="Banner 03" />
            </Banner>

            <Banner>
              <Image src="images/home/m_pt_04.png" alt="Banner 04" />
            </Banner>
          </Carousel>
        </ContainerSmallScreen>
      </>
    );
  }

  return <>{handleLanguageBanners()}</>;
};

export default HomeImageMultiCarousel;
