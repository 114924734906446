import React from 'react';

import Separator from '~/components/Separator';

import { useAppLanguage } from '~/hooks/language';
import { getLanguageInfo } from '~/utils';

import {
  MainContainer,
  Title,
  MainContent,
  FlipContainer,
  Flipper,
  Front,
  Back,
  ContentItemComplete,
  ContentItemBorder,
  ContentItemText,
  ContentSeparator,
} from './styles';

const Production = (): JSX.Element => {
  const { currentLanguage } = useAppLanguage();

  function handleItemChicksComplete(): React.ReactNode {
    if (currentLanguage === 'english') {
      return (
        <ContentItemComplete src="images/producao/completo_pintos_en.png" />
      );
    }

    if (currentLanguage === 'spanish') {
      return (
        <ContentItemComplete src="images/producao/completo_pintos_es.png" />
      );
    }

    return <ContentItemComplete src="images/producao/completo_pintos_pt.png" />;
  }

  function handleItemEggsComplete(): React.ReactNode {
    if (currentLanguage === 'english') {
      return <ContentItemComplete src="images/producao/completo_ovos_en.png" />;
    }

    if (currentLanguage === 'spanish') {
      return <ContentItemComplete src="images/producao/completo_ovos_es.png" />;
    }

    return <ContentItemComplete src="images/producao/completo_ovos_pt.png" />;
  }

  return (
    <MainContainer id="production">
      <Title>{getLanguageInfo(currentLanguage, 'production_title')}</Title>
      <MainContent>
        <FlipContainer>
          <Flipper>
            <Front>{handleItemChicksComplete()}</Front>

            <Back>
              <ContentItemBorder src="images/producao/borda.png" />
              <ContentItemText>
                {getLanguageInfo(currentLanguage, 'production_chicks_01')}
                <br />
                {getLanguageInfo(currentLanguage, 'production_chicks_02')}
                <br />
                {getLanguageInfo(currentLanguage, 'production_chicks_03')}
                <br />
                {getLanguageInfo(currentLanguage, 'production_chicks_04')}
              </ContentItemText>
            </Back>
          </Flipper>
        </FlipContainer>

        <ContentSeparator />

        <FlipContainer>
          <Flipper>
            <Front>{handleItemEggsComplete()}</Front>

            <Back>
              <ContentItemBorder src="images/producao/borda.png" />
              <ContentItemText>
                {getLanguageInfo(currentLanguage, 'production_eggs_01')}
                <br />
                {getLanguageInfo(currentLanguage, 'production_eggs_02')}
                <br />
                {getLanguageInfo(currentLanguage, 'production_eggs_03')}
                <br />
                {getLanguageInfo(currentLanguage, 'production_eggs_04')}
              </ContentItemText>
            </Back>
          </Flipper>
        </FlipContainer>
      </MainContent>
      <Separator />
    </MainContainer>
  );
};

export default Production;
