import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    justify-content: right;
    align-items: center;
    height: 4rem;
    padding: 0 2rem 0 2rem;
    background-color: ${theme.colors.veryDarkDesaturatedBlue};
  `}
`;
