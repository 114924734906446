import React, { useState } from 'react';
import { FaTimes, FaBars } from 'react-icons/fa';

import { useAppLanguage } from '~/hooks/language';
import { getLanguageInfo } from '~/utils';

import {
  NavBar,
  Container,
  LogoWrapper,
  Logo,
  Menu,
  Item,
  ItemLink,
  Icon,
} from './styles';

const Navbar = (): JSX.Element => {
  const { currentLanguage } = useAppLanguage();
  const [click, setClick] = useState(false);

  const handleClick = (): void => setClick(!click);

  const closeMobileMenu = (): void => setClick(false);

  return (
    <>
      <NavBar>
        <Container>
          <LogoWrapper>
            <Logo src="icons/logo_tipo_superior.png" />
          </LogoWrapper>

          <Icon onClick={handleClick}>{click ? <FaTimes /> : <FaBars />}</Icon>

          <Menu isActive={click}>
            <Item>
              <ItemLink
                to="home"
                smooth
                offset={-130}
                onClick={closeMobileMenu}
              >
                {getLanguageInfo(currentLanguage, 'nav_home')?.toUpperCase()}
              </ItemLink>
            </Item>

            <Item>
              <ItemLink
                to="aboutus"
                smooth
                offset={-130}
                onClick={closeMobileMenu}
              >
                {getLanguageInfo(
                  currentLanguage,
                  'nav_about_us',
                )?.toUpperCase()}
              </ItemLink>
            </Item>

            <Item>
              <ItemLink
                to="ourstory"
                smooth
                offset={-130}
                onClick={closeMobileMenu}
              >
                {getLanguageInfo(
                  currentLanguage,
                  'nav_our_story',
                )?.toUpperCase()}
              </ItemLink>
            </Item>

            <Item>
              <ItemLink
                to="production"
                smooth
                offset={-130}
                onClick={closeMobileMenu}
              >
                {getLanguageInfo(
                  currentLanguage,
                  'nav_production',
                )?.toUpperCase()}
              </ItemLink>
            </Item>

            <Item>
              <ItemLink
                to="certifications"
                smooth
                offset={-130}
                onClick={closeMobileMenu}
              >
                {getLanguageInfo(
                  currentLanguage,
                  'nav_certifications',
                )?.toUpperCase()}
              </ItemLink>
            </Item>

            <Item>
              <ItemLink
                to="peoplemanagement"
                smooth
                offset={300}
                onClick={closeMobileMenu}
              >
                {getLanguageInfo(
                  currentLanguage,
                  'nav_people_management',
                )?.toUpperCase()}
              </ItemLink>
            </Item>

            <Item>
              <ItemLink
                to="contact"
                smooth
                offset={-130}
                onClick={closeMobileMenu}
              >
                {getLanguageInfo(currentLanguage, 'nav_contact')?.toUpperCase()}
              </ItemLink>
            </Item>
          </Menu>
        </Container>
      </NavBar>
    </>
  );
};

export default Navbar;
