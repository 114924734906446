import React, { useState } from 'react';

import PrivacyDialog from '../PrivacyDialog';

import { useAppLanguage } from '~/hooks/language';
import { useAppCookies } from '~/hooks/cookies';
import { getLanguageInfo } from '~/utils';

import {
  Container,
  Wrapper,
  Content,
  Info,
  InfoPrivacyPolicyLink,
  InfoPrivacyDialogLink,
  AcceptButton,
} from './styles';

const PrivacyBar: React.FC = () => {
  const { currentLanguage } = useAppLanguage();
  const { cookiesEnabled, handleCookiesChange } = useAppCookies();
  const [isOpenPrivacyDialog, setIsOpenPrivacyDialog] = useState(false);

  function handlePrivacyBar(): React.ReactNode {
    if (!cookiesEnabled) {
      return (
        <Container>
          <Wrapper>
            <Content>
              <Info>
                {getLanguageInfo(currentLanguage, 'cookies_footer_01')}
              </Info>
              <Info>
                {getLanguageInfo(currentLanguage, 'cookies_footer_02')}
              </Info>
              <Info>
                <InfoPrivacyPolicyLink
                  to={{
                    pathname: `/politicadeprivacidade`,
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {getLanguageInfo(
                    currentLanguage,
                    'cookies_footer_privacy_policy',
                  )}
                </InfoPrivacyPolicyLink>
              </Info>
              <Info>
                {getLanguageInfo(currentLanguage, 'cookies_footer_03')}
              </Info>
              <Info>
                <InfoPrivacyDialogLink
                  onClick={() => setIsOpenPrivacyDialog(true)}
                >
                  {getLanguageInfo(
                    currentLanguage,
                    'cookies_footer_privacy_dialog',
                  )}
                </InfoPrivacyDialogLink>
              </Info>
              <Info>.</Info>
            </Content>

            <AcceptButton
              onClick={() => {
                handleCookiesChange(true);
              }}
            >
              Aceitar
            </AcceptButton>
          </Wrapper>
          <PrivacyDialog
            isOpen={isOpenPrivacyDialog}
            setIsOpen={setIsOpenPrivacyDialog}
          />
        </Container>
      );
    }

    return <></>;
  }

  return <>{handlePrivacyBar()}</>;
};

export default PrivacyBar;
