import styled, { css } from 'styled-components';
import Modal from 'react-modal';
import { shade } from 'polished';
import Button from '~/components/Button';

export const StyledModal = styled(Modal)`
  ${({ theme }) => css`
    margin-top: 10%;
    margin-left: 50%;
    transform: translate(-50%, -10%);
    background-color: ${theme.colors.veryDarkBlue};
    box-shadow: ${theme.shadows.default};
    border-radius: ${theme.radius.meddium};
    width: 100%;
    width: 80%;
    max-width: 680px;

    @media (max-width: 740px) {
      margin-top: 20%;
    }
  `}
`;

export const Container = styled.div`
  padding: 1rem 1rem 1rem 1rem;
`;

export const Title = styled.label`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes.xxLarge};
    margin-left: 2rem;
  `}
`;

export const Wrapper = styled.section`
  width: 100%;
  margin-bottom: 2rem;
  padding: 2rem 2rem 2rem 2rem;
  max-width: 680px;
`;

export const UrlImage = styled.img`
  ${({ theme }) => css`
    width: 100%;
    border-radius: ${theme.radius.small};
  `}
`;

export const ButtonContainer = styled.div`
  width: 100%;
`;

export const CloseButton = styled(Button)`
  ${({ theme }) => css`
    float: right;
    margin-right: -3.5rem;
    padding: 0;
    width: 5rem;
    height: 5rem;
    margin-top: -3.5rem;
    border-radius: 50%;
    background: ${theme.colors.white};
    color: ${theme.colors.veryDarkBlue};
    :hover {
      transform: scale(1.1, 1.1);
      background: ${shade(0.2, theme.colors.white)};
    }
  `}
`;
