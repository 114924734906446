import React from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import * as themes from './styles/themes';
import GlobalStyles from './styles/global';
import Main from './pages/Main';
import PrivacyPolicy from './pages/PrivacyPolicy';
import CookiesPolicy from './pages/CookiesPolicy';

import AppProvider from './hooks';
import useAppTheme from './hooks/theme';

const App: React.FC = () => {
  const { currentTheme } = useAppTheme();

  return (
    <ThemeProvider theme={themes[currentTheme]}>
      <AppProvider>
        <Router>
          <Switch>
            <Route path="/" exact component={Main} />
            <Route
              path="/politicadeprivacidade"
              exact
              component={PrivacyPolicy}
            />
            <Route path="/politicadecookies" exact component={CookiesPolicy} />
          </Switch>
        </Router>
      </AppProvider>
      <GlobalStyles />
    </ThemeProvider>
  );
};

export default App;
