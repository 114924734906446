import React from 'react';

import ExtrasNavbar from '../ExtrasNavbar';
import Navbar from '../Navbar';

import { Container } from './styles';

const Header = (): JSX.Element => {
  return (
    <Container>
      <ExtrasNavbar />
      <Navbar />
    </Container>
  );
};

export default Header;
