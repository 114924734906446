import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { FaTimes } from 'react-icons/fa';
import { FiAlertCircle, FiInfo, FiCheckCircle } from 'react-icons/fi';

import Toggle from './../../components/Toggle';

import { useAppLanguage } from '~/hooks/language';
import { useAppCookies } from '~/hooks/cookies';
import { getLanguageInfo } from '~/utils';

import {
  StyledModal,
  CloseButtonContainer,
  CloseButton,
  MainContainer,
  FirstContainer,
  Logo,
  OptionContainer,
  OptionWrapper,
  IconOption,
  InfoOption,
  SecondContainer,
  InfoContainer,
  InfoFirstWrapper,
  InfoSecondWrapper,
  InfoThirdWrapper,
  InfoTitle,
  InfoSpanWrapper,
  InfoSpanText,
  InfoSpanHighlightedText,
  InfoText,
  ButtonContainer,
  CompleteCookiesPolicyWrapper,
  CompleteCookiesPolicyLink,
  OptionsWrapper,
  OptionButton,
} from './styles';

interface IPrivacyDialogProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const PrivacyDialog: React.FC<IPrivacyDialogProps> = ({
  isOpen,
  setIsOpen,
}: IPrivacyDialogProps) => {
  const { currentLanguage } = useAppLanguage();
  const { cookiesEnabled, handleCookiesChange } = useAppCookies();
  const [isFirstWrapper, setIsFirstWrapper] = useState(true);
  const [isSecondWrapper, setIsSecondWrapper] = useState(false);
  const [isThirdWrapper, setIsThirdWrapper] = useState(false);
  const [tempCookiesEnabled, setTempCookiesEnabled] = useState(cookiesEnabled);

  useEffect(() => {
    setTempCookiesEnabled(cookiesEnabled);
  }, [cookiesEnabled]);

  const handleEnableAll = (): void => {
    handleCookiesChange(true);
    setTempCookiesEnabled(true);
    setIsOpen(false);
  };

  const handleSave = (): void => {
    handleCookiesChange(tempCookiesEnabled);
    setIsOpen(false);
  };

  const handleClose = (): void => {
    setTempCookiesEnabled(cookiesEnabled);
    setIsOpen(false);
  };

  function handleFirstOption(): void {
    if (!isFirstWrapper) {
      setIsSecondWrapper(false);
      setIsThirdWrapper(false);
      setIsFirstWrapper(true);
    }
  }

  function handleSecondOption(): void {
    if (!isSecondWrapper) {
      setIsFirstWrapper(false);
      setIsThirdWrapper(false);
      setIsSecondWrapper(true);
    }
  }

  function handleThirdOption(): void {
    if (!isThirdWrapper) {
      setIsFirstWrapper(false);
      setIsSecondWrapper(false);
      setIsThirdWrapper(true);
    }
  }

  function handleDisabledCookiesInfo(): React.ReactNode {
    if (!tempCookiesEnabled) {
      return (
        <InfoText>
          {getLanguageInfo(
            currentLanguage,
            'cookies_dialog_section_03_info_02',
          )}
        </InfoText>
      );
    }

    return <></>;
  }

  return (
    <StyledModal
      isOpen={isOpen}
      style={{
        overlay: {
          zIndex: 999,
          overflowY: 'auto',
          position: 'fixed',
          background: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <div>
        <CloseButtonContainer>
          <CloseButton onClick={() => handleClose()}>
            <FaTimes size={24} />
          </CloseButton>
        </CloseButtonContainer>

        <MainContainer>
          <FirstContainer>
            <Logo src="images/cookies/simbolo.png" />

            <OptionContainer>
              <OptionWrapper onClick={() => handleFirstOption()}>
                <IconOption>
                  <FiAlertCircle size={18} color="#000000" />
                </IconOption>

                <InfoOption>
                  {getLanguageInfo(
                    currentLanguage,
                    'cookies_dialog_menu_item_01',
                  )}
                </InfoOption>
              </OptionWrapper>

              <OptionWrapper onClick={() => handleSecondOption()}>
                <IconOption>
                  <FiInfo size={18} color="#000000" />
                </IconOption>

                <InfoOption>
                  {getLanguageInfo(
                    currentLanguage,
                    'cookies_dialog_menu_item_02',
                  )}
                </InfoOption>
              </OptionWrapper>

              <OptionWrapper onClick={() => handleThirdOption()}>
                <IconOption>
                  <FiCheckCircle size={18} color="#000000" />
                </IconOption>

                <InfoOption>
                  {getLanguageInfo(
                    currentLanguage,
                    'cookies_dialog_menu_item_03',
                  )}
                </InfoOption>
              </OptionWrapper>
            </OptionContainer>
          </FirstContainer>

          <SecondContainer>
            <InfoContainer>
              <InfoFirstWrapper isVisible={isFirstWrapper}>
                <InfoTitle>
                  {getLanguageInfo(
                    currentLanguage,
                    'cookies_dialog_section_01_title',
                  )}
                </InfoTitle>

                <InfoSpanWrapper>
                  <InfoSpanText>
                    {getLanguageInfo(
                      currentLanguage,
                      'cookies_dialog_section_01_info_01',
                    )}
                  </InfoSpanText>

                  <InfoSpanHighlightedText>
                    {getLanguageInfo(
                      currentLanguage,
                      'cookies_dialog_section_01_info_02',
                    )}
                  </InfoSpanHighlightedText>

                  <InfoSpanText>
                    {getLanguageInfo(
                      currentLanguage,
                      'cookies_dialog_section_01_info_03',
                    )}
                  </InfoSpanText>
                </InfoSpanWrapper>
              </InfoFirstWrapper>

              <InfoSecondWrapper isVisible={isSecondWrapper}>
                <InfoTitle>
                  {getLanguageInfo(
                    currentLanguage,
                    'cookies_dialog_section_02_title',
                  )}
                </InfoTitle>

                <InfoText>
                  {getLanguageInfo(
                    currentLanguage,
                    'cookies_dialog_section_02_info',
                  )}
                </InfoText>
              </InfoSecondWrapper>

              <InfoThirdWrapper isVisible={isThirdWrapper}>
                <InfoTitle>
                  {getLanguageInfo(
                    currentLanguage,
                    'cookies_dialog_section_03_title',
                  )}
                </InfoTitle>

                <InfoText>
                  {getLanguageInfo(
                    currentLanguage,
                    'cookies_dialog_section_03_info_01',
                  )}
                </InfoText>

                <Toggle
                  onChange={e => setTempCookiesEnabled(e)}
                  checked={tempCookiesEnabled}
                />

                {handleDisabledCookiesInfo()}
              </InfoThirdWrapper>
            </InfoContainer>

            <ButtonContainer>
              <CompleteCookiesPolicyWrapper>
                <CompleteCookiesPolicyLink
                  to={{
                    pathname: `/politicadecookies`,
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {getLanguageInfo(
                    currentLanguage,
                    'cookies_dialog_cookies_policy_link',
                  )}
                </CompleteCookiesPolicyLink>
              </CompleteCookiesPolicyWrapper>

              <OptionsWrapper>
                <OptionButton onClick={() => handleEnableAll()}>
                  {getLanguageInfo(
                    currentLanguage,
                    'cookies_dialog_button_enable_all',
                  )}
                </OptionButton>

                <OptionButton onClick={() => handleSave()}>
                  {getLanguageInfo(
                    currentLanguage,
                    'cookies_dialog_button_save',
                  )}
                </OptionButton>
              </OptionsWrapper>
            </ButtonContainer>
          </SecondContainer>
        </MainContainer>
      </div>
    </StyledModal>
  );
};

export default PrivacyDialog;
