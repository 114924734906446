import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin-top: 3.5rem;
  padding: 0 10rem 0 10rem;

  @media (max-width: 1220px) {
    justify-content: center;
    padding: 0;
  }
`;

export const ContentWrapper = styled.div`
  margin-bottom: 5rem;
  text-align: center;
`;

export const ImageXLarge = styled.img`
  width: 25rem;
`;

export const ImageXXLarge = styled.img`
  width: 22.5rem;
`;

export const YearWrapper = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes.xxxLarge};
    font-weight: bold;
  `}
`;
