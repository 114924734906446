import styled, { css } from 'styled-components';

export const MainContainer = styled.main`
  ${({ theme }) => css`
    width: 100%;
    background: ${theme.colors.white};
    padding-top: 3rem;
  `}
`;

export const ContentContainer = styled.div`
  width: 100%;
  padding: 0 10rem 0 10rem;
  margin-bottom: 5rem;

  @media (max-width: 615px) {
    padding: 0 2rem 0 2rem;
  }
`;

export const ContentTitle = styled.div`
  ${({ theme }) => css`
    width: 100%;
    color: ${theme.colors.strongRed};
    font-weight: bold;
    font-size: ${theme.fontSizes.xxxxLarge};
    margin-bottom: 2rem;
  `}
`;

export const ContentSubtitle = styled.div`
  ${({ theme }) => css`
    width: 100%;
    color: ${theme.colors.strongRed};
    font-weight: bold;
    font-size: ${theme.fontSizes.xxxLarge};
    margin-bottom: 2rem;
  `}
`;

export const ContentTextWrapper = styled.div`
  width: 100%;
  padding-bottom: 1rem;
`;

export const ContentText = styled.div`
  ${({ theme }) => css`
    text-align: justify;
    text-indent: 5rem;
    line-height: 2;
    color: ${theme.colors.veryDarkBlue};
    font-size: ${theme.fontSizes.large};
  `}
`;

export const ContentHighlightedText = styled.div`
  ${({ theme }) => css`
    text-align: justify;
    text-indent: 5rem;
    line-height: 2;
    color: ${theme.colors.veryDarkBlue};
    font-size: ${theme.fontSizes.large};
    font-weight: bold;
  `}
`;

export const ContentItemsWrapper = styled.div`
  padding-bottom: 2rem;
`;

export const ContentItemWrapper = styled.div`
  display: flex;
  width: 100%;
  padding-left: 5rem;
`;

export const ContentItemIndexWrapper = styled.div``;

export const ContentItemInfoWrapper = styled.div`
  padding-left: 1rem;
  text-align: justify;
`;

export const ContentItemIndexFirstType = styled.span`
  ${({ theme }) => css`
    line-height: 2;
    color: ${theme.colors.veryDarkBlue};
    font-size: ${theme.fontSizes.large};
  `}
`;

export const ContentItemTitleFirstType = styled.span`
  ${({ theme }) => css`
    line-height: 2;
    color: ${theme.colors.veryDarkBlue};
    font-size: ${theme.fontSizes.large};
    font-weight: bold;
  `}
`;

export const ContentItemText = styled.span`
  ${({ theme }) => css`
    line-height: 2;
    color: ${theme.colors.veryDarkBlue};
    font-size: ${theme.fontSizes.large};
  `}
`;
