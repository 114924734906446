import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    position: fixed;
    width: 100%;
    height: 6rem;
    bottom: 0rem;
    background: ${theme.colors.veryDarkGrey};
    border-top: 1px solid ${theme.colors.secondaryLabel};
    align-items: center;
    justify-content: center;
    z-index: 999;
    padding: 0 2rem 0 2rem;

    @media (max-width: 730px) {
      height: 12rem;
    }
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 70rem;
  justify-content: center;
`;

export const Content = styled.div`
  align-items: center;
  margin-left: 1rem;
`;

export const Info = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes.default};
    font-weight: bold;
  `}
`;

export const InfoPrivacyPolicyLink = styled(Link)`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes.meddium};
    text-decoration: underline;
  `}
`;

export const InfoPrivacyDialogLink = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes.meddium};
    text-decoration: underline;

    :hover {
      cursor: pointer;
    }
  `}
`;

export const AcceptButton = styled.button`
  ${({ theme }) => css`
    cursor: pointer;
    background-color: ${theme.colors.veryDarkBlue};
    border-radius: ${theme.radius.xxxxxLarge};
    color: #fff;
    height: 4rem;
    width: 20rem;
    transition: all 0.3s ease-out;
    font-size: ${theme.fontSizes.default};
    font-weight: bold;
    margin-left: 2rem;

    :hover {
      background: ${theme.colors.buttonBackgroundHover};
      transition: all 0.3s ease-out;
    }
  `}
`;
