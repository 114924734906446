import React, {
  InputHTMLAttributes,
  useState,
  useCallback,
  useRef,
} from 'react';
import { FiAlertOctagon } from 'react-icons/fi';

import { Container, PhoneInputContainer, Label, Error } from './styles';

interface IPhoneInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  labelFor?: string;
  labelText?: string;
  showLabel?: boolean;
  error?: string;
  onUpdate?: (e: any) => void;
}

const PhoneInput = React.forwardRef<HTMLInputElement, IPhoneInputProps>(
  (
    {
      name,
      labelText,
      labelFor,
      showLabel,
      error,
      onUpdate,
      ...rest
    }: IPhoneInputProps,
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);
    const keyType = useRef('');

    const handleInputFocus = useCallback(() => {
      setIsFocused(!isFocused);
    }, [isFocused]);

    const handleInputBlur = useCallback(() => {
      setIsFocused(false);
    }, []);

    const handleChange = (e: any): void => {
      let inputValue = '';
      let mask = '(##)_####-####';
      let maskedInput = '';
      let inputValueIndex = 0;
      let maskedInputLength = 0;
      let cursorPosition = e.target.selectionStart;

      if (e.target.value) {
        for (let i = 0; i < e.target.value.length; i += 1) {
          if (
            e.target.value[i] === '0' ||
            e.target.value[i] === '1' ||
            e.target.value[i] === '2' ||
            e.target.value[i] === '3' ||
            e.target.value[i] === '4' ||
            e.target.value[i] === '5' ||
            e.target.value[i] === '6' ||
            e.target.value[i] === '7' ||
            e.target.value[i] === '8' ||
            e.target.value[i] === '9'
          ) {
            inputValue += e.target.value[i];
          }
        }

        if (inputValue.length > 10) {
          mask = '(##)_#####-####';
        }

        for (let i = 0; i < mask.length; i += 1) {
          if (mask[i] === '#' && inputValue[inputValueIndex]) {
            maskedInput += inputValue[inputValueIndex];
            inputValueIndex += 1;
          } else {
            maskedInput += mask[i];
          }
        }

        maskedInput = maskedInput.replaceAll('#', '');
        maskedInput = maskedInput.replaceAll('_', ' ');

        maskedInputLength = maskedInput.length - 1;
        for (let i = maskedInput.length - 1; i >= 0; i -= 1) {
          if (
            maskedInputLength === i &&
            (maskedInput[i] === '(' ||
              maskedInput[i] === ')' ||
              maskedInput[i] === '-' ||
              maskedInput[i] === ' ')
          ) {
            maskedInputLength -= 1;
          }
        }

        maskedInput = maskedInput.substr(0, maskedInputLength + 1);

        e.target.value = maskedInput;

        for (let i = 0; i < maskedInput.length; i += 1) {
          if (
            i + 1 === cursorPosition &&
            (maskedInput[i] === '(' ||
              maskedInput[i] === ')' ||
              maskedInput[i] === '-' ||
              maskedInput[i] === ' ')
          ) {
            if (keyType.current !== 'Backspace') {
              cursorPosition += 1;
            }
          }
        }

        e.target.selectionStart = cursorPosition;
        e.target.selectionEnd = cursorPosition;

        if (onUpdate) {
          onUpdate(e);
        }
      }
    };

    return (
      <Container>
        {showLabel && <Label isFocused={isFocused}>{labelText}</Label>}

        <PhoneInputContainer
          id={labelFor}
          isFocused={isFocused}
          isErrored={!!error}
        >
          <input
            name={name}
            type="type"
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onKeyDown={e => {
              keyType.current = e.key;
            }}
            onChange={e => {
              handleChange(e);
            }}
            ref={ref}
            {...rest}
          />
        </PhoneInputContainer>
        {error && (
          <Error title={error}>
            <FiAlertOctagon color="#c91918" size={20} />
          </Error>
        )}
      </Container>
    );
  },
);

export default PhoneInput;
