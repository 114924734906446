import React from 'react';
import { OptionTypeBase, Props as SelectProps } from 'react-select';
import WindowedSelect from 'react-windowed-select';

import { FiAlertOctagon } from 'react-icons/fi';

import { useTheme } from 'styled-components';

import { Container, SelectContainer, Label, Error } from './styles';

interface ISelectFilterProps extends SelectProps<OptionTypeBase> {
  name: string;
  label: string;
  placeholder?: string;
  showLabel?: boolean;
  options:
    | {
        value: string;
        label: string;
      }[]
    | undefined;
  onUpdate?: (e: any) => void;
}

const SelectFilter = React.forwardRef<HTMLSelectElement, ISelectFilterProps>(
  (
    {
      name,
      label,
      showLabel,
      placeholder,
      options,
      onUpdate,
      loading,
      error,
      ...rest
    },
    ref,
  ) => {
    const { colors, radius } = useTheme();

    function getOptionBackgroundColor(
      isSelected: boolean,
      isFocused: boolean,
    ): string {
      if (isSelected) {
        return colors.darkGrey;
      }
      if (isFocused) {
        return colors.grey;
      }

      return colors.background;
    }

    const customStyles = {
      zIndex: 999,
      option: (provided: any, state: any) => ({
        ...provided,
        color: state.isSelected ? colors.lightGrey : '#000000',
        padding: 6,
        fontSize: 16,
        backgroundColor: getOptionBackgroundColor(
          state.isSelected,
          state.isFocused,
        ),
        '&:hover': {
          background: state.isSelected ? colors.darkGrey : colors.grey,
        },
      }),
      control: (provided: any) => ({
        ...provided,
        background: colors.background,
        borderColor: colors.strongRed,
        borderRadius: radius.large,
        padding: 1,
        margin: '6px 0px 6px 6px',
        height: 45,
        minHeight: 45,
        fontSize: 16,
      }),
      valueContainer: (provided: any) => ({
        ...provided,
        height: 42,
        minHeight: 42,
        background: colors.background,
        borderRadius: radius.large,
      }),
      input: (provided: any) => ({
        ...provided,
        margin: '0px',
      }),
      indicatorsContainer: (provided: any) => ({
        ...provided,
        height: 42,
        minHeight: 42,
        background: colors.background,
        borderRadius: radius.large,
      }),
      menuPortal: (provided: any) => ({ ...provided, zIndex: 9999 }),
    };

    const handleUpdate = (e: any): void => {
      if (onUpdate) {
        onUpdate(e);
      }
    };

    return (
      <Container>
        {showLabel && (
          <Label htmlFor={name} isFocused={false}>
            {label}
          </Label>
        )}

        <SelectContainer isFocused={false} isErrored={!!error}>
          <WindowedSelect
            name={name}
            placeholder={
              placeholder !== undefined ? placeholder : 'Selecione ...'
            }
            options={options}
            isLoading={loading}
            inputRef={ref}
            styles={customStyles}
            menuPortalTarget={document.body}
            noOptionsMessage={() => {
              return 'Nenhum assunto encontrado';
            }}
            theme={(theme: any) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: colors.brightOrange,
              },
            })}
            onChange={(e: any) => {
              handleUpdate(e);
            }}
            {...rest}
          />
        </SelectContainer>
        {error && (
          <Error title={error}>
            <FiAlertOctagon color="#c91918" size={20} />
          </Error>
        )}
      </Container>
    );
  },
);

export default SelectFilter;
