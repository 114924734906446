import styled, { css } from 'styled-components';
import Modal from 'react-modal';
import { shade } from 'polished';
import { Link } from 'react-router-dom';
import Button from '~/components/Button';

interface IInfoFirstWrapperProps {
  isVisible: boolean;
}

interface IInfoSecondWrapperProps {
  isVisible: boolean;
}

interface IInfoThirdWrapperProps {
  isVisible: boolean;
}

export const StyledModal = styled(Modal)`
  ${({ theme }) => css`
    margin-top: 15%;
    margin-left: 50%;
    transform: translate(-50%, -10%);
    background-color: #eee;
    box-shadow: ${theme.shadows.default};
    border-radius: ${theme.radius.meddium};
    width: 800px;
    height: 425px;

    @media (max-width: 1200px) {
      margin-top: 20%;
    }

    @media (max-width: 995px) {
      margin-top: 20%;
      width: 350px;
      height: 720px;
    }

    @media (max-width: 615px) {
      margin-top: 15rem;
      width: 350px;
      height: 720px;
    }

    @media (max-width: 450px) {
      margin-top: 40rem;
      width: 350px;
      height: 720px;
    }
  `}
`;

export const CloseButtonContainer = styled.div`
  width: 100%;
  zindex: 9999;
`;

export const CloseButton = styled(Button)`
  ${({ theme }) => css`
    float: right;
    margin-right: -2.5rem;
    padding: 0;
    width: 5rem;
    height: 5rem;
    margin-top: -2.5rem;
    border-radius: 50%;
    background: ${theme.colors.veryDarkBlue};
    color: ${theme.colors.white};
    :hover {
      transform: scale(1.1, 1.1);
      background: ${shade(0.2, theme.colors.white)};
    }
  `}
`;

export const MainContainer = styled.div`
  display: flex;

  @media (max-width: 995px) {
    flex-direction: column;
  }
`;

export const FirstContainer = styled.div`
  ${({ theme }) => css`
    width: 350px;
    background: ${theme.colors.primaryBackground};
    border-top-left-radius: ${theme.radius.meddium};
    border-bottom-left-radius: ${theme.radius.meddium};
  `}
`;

export const Logo = styled.img`
  width: 8rem;
  margin-top: 1rem;
  margin-left: 7rem;
`;

export const OptionContainer = styled.div`
  margin-top: 2rem;
  margin-left: 1rem;
`;

export const OptionWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    height: 4rem;

    :hover {
      cursor: pointer;
      transform: scale(1.04);
      background: ${theme.colors.secondaryBackground};
    }
  `}
`;

export const IconOption = styled.div`
  margin-left: 1rem;
`;

export const InfoOption = styled.div`
  ${({ theme }) => css`
    margin-left: 0.5rem;
    font-size: ${theme.fontSizes.small};
  `}
`;

export const SecondContainer = styled.div`
  width: 550px;

  @media (max-width: 995px) {
    flex-direction: column;
    width: 350px;
    overflow: scroll;
  }
`;

export const InfoContainer = styled.div`
  height: 300px;
`;

export const InfoFirstWrapper = styled.div<IInfoFirstWrapperProps>`
  ${({ isVisible }) => css`
    ${!isVisible &&
    css`
      display: none;
    `}
  `}
`;

export const InfoSecondWrapper = styled.div<IInfoSecondWrapperProps>`
  ${({ isVisible }) => css`
    ${!isVisible &&
    css`
      display: none;
    `}
  `}
`;

export const InfoThirdWrapper = styled.div<IInfoThirdWrapperProps>`
  ${({ isVisible }) => css`
    ${!isVisible &&
    css`
      display: none;
    `}
  `}
`;

export const InfoTitle = styled.div`
  ${({ theme }) => css`
    margin-top: 2rem;
    font-size: ${theme.fontSizes.xLarge};
    text-align: center;
    color: ${theme.colors.veryDarkGrey};
    font-weight: bold;
  `}
`;

export const InfoSpanWrapper = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.small};
    text-align: justify;
    text-justify: inter-word;
    margin-top: 2rem;
    padding: 0rem 3rem 0rem 3rem;
  `}
`;

export const InfoSpanText = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.small};
  `}
`;

export const InfoSpanHighlightedText = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.small};
    font-weight: bold;
  `}
`;

export const InfoText = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.small};
    text-align: justify;
    text-justify: inter-word;
    margin-top: 2rem;
    padding: 0rem 3rem 0rem 3rem;
  `}
`;

export const ButtonContainer = styled.div`
  width: 100%;
  padding: 2rem 5rem 1.2rem 5rem;
`;

export const CompleteCookiesPolicyWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;
`;

export const CompleteCookiesPolicyLink = styled(Link)`
  ${({ theme }) => css`
    color: ${theme.colors.strongRed};
    font-size: ${theme.fontSizes.small};
    font-weight: 400;
    text-align: center;

    &:hover {
      transform: scale(1.1, 1.1);
    }
  `}
`;

export const OptionsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const OptionButton = styled.button`
  ${({ theme }) => css`
    cursor: pointer;
    width: 13rem;
    background-color: ${theme.colors.veryDarkBlue};
    border-radius: ${theme.radius.xxxxxLarge};
    color: ${theme.colors.white};
    padding: 1rem 3rem;
    border: 1px solid var(--primary);
    font-size: ${theme.fontSizes.tiny};
    margin-left: 1rem;

    :hover {
      background: ${theme.colors.buttonBackgroundHover};
      transition: all 0.3s ease-out;
    }
  `}
`;
