import React, { Dispatch, SetStateAction } from 'react';
import { FaTimes } from 'react-icons/fa';

import {
  StyledModal,
  Container,
  Title,
  Wrapper,
  UrlImage,
  ButtonContainer,
  CloseButton,
} from './styles';

interface IPMGalleryModalProps {
  isOpen: boolean;
  onClose: () => void;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  title: string;
  url: string;
}

const PMGalleryModal: React.FC<IPMGalleryModalProps> = ({
  isOpen,
  onClose,
  setIsOpen,
  title,
  url,
}: IPMGalleryModalProps) => {
  const handleClose = (): void => {
    setIsOpen(false);
    onClose();
  };

  return (
    <StyledModal
      isOpen={isOpen}
      style={{
        overlay: {
          zIndex: 999,
          overflowY: 'auto',
          position: 'fixed',
          background: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <Container>
        <ButtonContainer>
          <CloseButton onClick={() => handleClose()}>
            <FaTimes size={30} />
          </CloseButton>
        </ButtonContainer>

        <Title>{title}</Title>

        <Wrapper>
          <UrlImage src={url} />
        </Wrapper>
      </Container>
    </StyledModal>
  );
};

export default PMGalleryModal;
