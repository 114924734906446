import React, { createContext, useCallback, useContext } from 'react';
import CryptoJS from 'crypto-js';

import api from '../services/api';

interface IAuthContextData {
  setToken(): Promise<void>;
  removeToken(): void;
}

const AuthContext = createContext<IAuthContextData>({} as IAuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const setToken = useCallback(async () => {
    let token = '';

    if (
      process.env.REACT_APP_AVICOLAPB_SECRET &&
      process.env.REACT_APP_AVICOLAPB_KEY
    ) {
      token = CryptoJS.AES.encrypt(
        process.env.REACT_APP_AVICOLAPB_SECRET,
        process.env.REACT_APP_AVICOLAPB_KEY,
      ).toString();
    }

    localStorage.setItem('@AvicolaPB:token', token);

    api.defaults.headers.authorization = `Barer ${token}`;
  }, []);

  const removeToken = useCallback(() => {
    localStorage.removeItem('@AvicolaPB:token');

    api.defaults.headers.authorization = '';
  }, []);

  return (
    <AuthContext.Provider value={{ setToken, removeToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): IAuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
