import styled, { css } from 'styled-components';

export const MainContainer = styled.main`
  ${({ theme }) => css`
    width: 100%;
    background: ${theme.colors.white};
  `}
`;

export const Title = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin-top: 4rem;
    margin-bottom: 2rem;
    text-align: center;
    color: ${theme.colors.veryDarkBlue};
    font-weight: bold;
    font-size: ${theme.fontSizes.xxxxLarge};
  `}
`;

export const MainContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 15rem;

  @media (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Flipper = styled.div`
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  position: relative;
`;

export const FlipContainer = styled.div`
  width: 300px;
  height: 200px;
  perspective: 1000px;

  :hover ${Flipper} {
    transform: rotateY(180deg);
  }

  @media (max-width: 1100px) {
    margin-bottom: 10rem;
  }
`;

export const Front = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotateX(0deg);
  backface-visibility: hidden;
`;

export const Back = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotateY(180deg);
  backface-visibility: hidden;
`;

export const ContentItemComplete = styled.img`
  width: 32rem;
`;

export const ContentItemBorder = styled.img`
  width: 32rem;
`;

export const ContentItemText = styled.div`
  ${({ theme }) => css`
    position: absolute;
    margin-top: -200px;
    width: 100%;
    height: 100%;
    color: ${theme.colors.veryDarkBlue};
    line-height: 2.6;
    font-size: ${theme.fontSizes.small};
    text-align: center;
  `}
`;

export const ContentSeparator = styled.div`
  width: 25rem;
  height: 5rem;
`;
