import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin: 3.5rem 0 2rem 0;
  padding: 0 10rem 0 10rem;

  @media (max-width: 1225px) {
    padding: 0;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Flipper = styled.div`
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  position: relative;

  :hover {
    cursor: pointer;
  }
`;

export const FlipContainer = styled.div`
  width: 250px;
  height: 300px;
  perspective: 1000px;

  :hover ${Flipper} {
    transform: rotateY(180deg);
  }
`;

export const Front = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotateX(0deg);
  backface-visibility: hidden;
`;

export const Back = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotateY(180deg);
  backface-visibility: hidden;
`;

export const ContentItemBorder = styled.img`
  width: 25rem;
`;

export const ContentItemImageWrapper = styled.div`
  background: url('images/gestao_de_pessoas/galeria/borda.png');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 25rem;
  height: 25rem;
`;

export const ContentItemImage = styled.img`
  width: 24rem;
  height: 24rem;
  border-radius: 50%;
  margin-top: 0.6rem;
  margin-left: 0.5rem;
`;

export const ContentItemText = styled.div`
  ${({ theme }) => css`
    position: absolute;
    margin-top: -150px;
    width: 100%;
    height: 100%;
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes.xxLarge};
    text-align: center;
  `}
`;
