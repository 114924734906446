import styled, { css } from 'styled-components';

interface IContentItemValuesCompleteProps {
  isPortuguese: boolean;
}

export const MainContainer = styled.main`
  ${({ theme }) => css`
    width: 100%;
    background: ${theme.colors.white};
  `}
`;

export const Title = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin-top: 4rem;
    text-align: center;
    color: ${theme.colors.veryDarkBlue};
    font-weight: bold;
    font-size: ${theme.fontSizes.xxxxLarge};
  `}
`;

export const Subtitle = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin-top: 1.5rem;
    text-align: center;
    color: ${theme.colors.veryDarkBlue};
    font-size: ${theme.fontSizes.large};
  `}
`;

export const SubtitleHighLight = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.strongRed};
  `}
`;

export const ArrowLargeScreenContainer = styled.div`
  width: 50%;
  text-align: right;

  @media (max-width: 1100px) {
    display: none;
  }
`;

export const ArrowLargeScreen = styled.img`
  width: 40rem;
`;

export const ArrowSmallScreenContainer = styled.div`
  width: 100%;
  text-align: center;

  @media (min-width: 1101px) {
    display: none;
  }
`;

export const ArrowSmallScreen = styled.img`
  width: 2.5rem;
`;

export const MainContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
    padding-left: 6rem;
  }
`;

export const Flipper = styled.div`
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  position: relative;
`;

export const FlipContainer = styled.div`
  width: 300px;
  height: 200px;
  perspective: 1000px;
  margin-right: 6rem;

  :hover ${Flipper} {
    transform: rotateY(180deg);
  }

  @media (max-width: 1100px) {
    margin-bottom: 10rem;
  }
`;

export const Front = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotateX(0deg);
  backface-visibility: hidden;
`;

export const Back = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotateY(180deg);
  backface-visibility: hidden;
`;

export const ContentItemMissionComplete = styled.img`
  width: 32rem;
`;

export const ContentItemVisionComplete = styled.img`
  width: 32rem;
`;

export const ContentItemValuesComplete = styled.img<IContentItemValuesCompleteProps>`
  ${({ isPortuguese }) => css`
    width: 34rem;

    ${isPortuguese &&
    css`
      @media (max-width: 1100px) {
        margin-left: -2rem;
      }
    `}
  `}
`;

export const ContentItemMissionBorder = styled.img`
  width: 32rem;
`;

export const ContentItemVisionBorder = styled.img`
  width: 32rem;
`;

export const ContentItemValuesBorder = styled.img`
  width: 30rem;
`;

export const ContentItemMissionText = styled.div`
  ${({ theme }) => css`
    position: absolute;
    margin-top: -220px;
    margin-left: -1rem;
    width: 100%;
    height: 100%;
    color: ${theme.colors.veryDarkBlue};
    line-height: 2.6;
    font-size: ${theme.fontSizes.small};
    text-align: center;
  `}
`;

export const ContentItemVisionText = styled.div`
  ${({ theme }) => css`
    position: absolute;
    margin-top: -190px;
    width: 100%;
    height: 100%;
    color: ${theme.colors.veryDarkBlue};
    line-height: 2.6;
    font-size: ${theme.fontSizes.small};
    text-align: center;
  `}
`;

export const ContentItemValuesText = styled.div`
  ${({ theme }) => css`
    position: absolute;
    margin-top: -200px;
    width: 100%;
    height: 100%;
    color: ${theme.colors.veryDarkBlue};
    line-height: 2.6;
    font-size: ${theme.fontSizes.small};
    text-align: center;
    padding: 0 25px 0 25px;
  `}
`;

export const ExtraInfoTopLine = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin-top: 12rem;
    text-align: center;
    color: ${theme.colors.veryDarkBlue};
    font-size: ${theme.fontSizes.xxLarge};

    @media (max-width: 1100px) {
      margin-top: 5rem;
      padding: 0 1rem 0 1rem;
      font-size: ${theme.fontSizes.default};
    }
  `}
`;

export const ExtraInfoBottomLine = styled.div`
  ${({ theme }) => css`
    padding: 1.5rem 0 3rem 8rem;
    text-align: center;
    color: ${theme.colors.veryDarkBlue};
    font-size: ${theme.fontSizes.xxLarge};

    @media (max-width: 1100px) {
      padding: 1.5rem 1rem 3rem 1rem;
      font-size: ${theme.fontSizes.default};
    }
  `}
`;

export const ExtraInfoHighLight = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.strongRed};
    font-weight: 1000;
  `}
`;
