import styled, { css } from 'styled-components';
import { HashLink } from 'react-router-hash-link';

interface IUlProps {
  isActive: boolean;
}

export const NavBar = styled.nav`
  ${({ theme }) => css`
    background: ${theme.colors.veryDarkBlue};
    box-shadow: ${theme.shadows.meddium};
    height: 11rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
  `}
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 11rem;
  max-width: 1500px;
`;

export const LogoWrapper = styled.div`
  display: flex;
  width: 400px;
  padding-right: 50px;
  padding-left: 20px;
`;

export const Logo = styled.img`
  display: flex;
  width: 300px;

  @media screen and (max-width: 450px) {
    width: 250px;
  }
`;

export const Menu = styled.ul<IUlProps>`
  ${({ theme, isActive }) => css`
    display: flex;
    width: 100%;

    @media screen and (max-width: 1220px) {
      margin-left: 0;
      display: none;
      flex-direction: column;
      width: 35rem;
      height: 90vh;
      position: absolute;
      top: 11rem;
      right: -100%;
      opacity: 1;
      transition: all 0.5s ease;

      ${isActive &&
      css`
        display: flex;
        background: ${theme.colors.veryDarkBlue};
        right: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
      `}
    }
  `}
`;

export const Item = styled.li`
  height: 11rem;
  display: flex;

  @media screen and (max-width: 1220px) {
    height: 8rem;
  }
`;

export const ItemLink = styled(HashLink)`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: ${theme.fontSizes.large};
    font-weight: 400;
    width: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    :hover {
      background: ${theme.colors.strongRed};
      transition: all 0.2s ease-out;
    }

    @media screen and (max-width: 1220px) {
      text-align: center;
      align-items: center;
      width: 100%;

      :hover {
        background: ${theme.colors.strongRed};
      }
    }
  `}
`;

export const Icon = styled.div`
  ${({ theme }) => css`
    display: none;
    color: ${theme.colors.white};

    @media screen and (max-width: 1220px) {
      display: block;
      position: absolute;
      right: 2rem;
      top: 3.5rem;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  `}
`;
