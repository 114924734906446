import styled, { css } from 'styled-components';
import { IButtonProps } from '.';

export const Container = styled.button<IButtonProps>`
  ${({ theme }) => css`
    padding: 8px 20px;
    border-radius: 2px;
    outline: NamedNodeMap;
    border: NamedNodeMap;
    cursor: PointerEvent;
    background-color: ${theme.colors.primary};
    border-radius: ${theme.radius.small};
    color: #fff;
    padding: 1.2rem 4.8rem;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
    font-size: ${theme.fontSizes.large};

    :hover {
      background: ${theme.colors.buttonBackgroundHover};
      transition: all 0.3s ease-out;
    }
  `}
`;
