import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

import Button from '~/components/Button';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18rem;
    position: relative;
    cursor: pointer;
    padding: 1.4rem;
    height: 3rem;
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes.small};
    font-weight: bold;
    border: 1px solid ${theme.colors.white};
    border-radius: ${theme.radius.small};

    :hover {
      background: ${theme.colors.strongRed};
      transition: all 0.2s ease-out;
    }
  `}
`;

export const AnimatedDropdown = styled(motion.nav)`
  ${({ theme }) => css`
    position: absolute;
    z-index: 9999;
    top: 195%;
    right: 0;
    width: 15rem;
    border-radius: ${theme.radius.small};
    background: ${theme.colors.veryDarkDesaturatedBlue};
    box-shadow: 0 0.6rem 0.6rem rgba(0, 0, 0, 0.12);

    ::before {
      content: '';
      display: block;
      position: absolute;
      top: -60%;
      right: 10%;
      border-color: ${`transparent transparent ${theme.colors.white} transparent `};
      border-style: solid;
      border-width: 1.6rem;
    }
  `}
`;

export const NavButton = styled(Button)`
  ${({ theme }) => css`
    width: 100%;
    padding: 0.6rem 1.5rem;
    font-size: ${theme.fontSizes.small};
    background: ${theme.colors.veryDarkDesaturatedBlue};
    border: 1px solid ${theme.colors.white};

    :hover {
      background: ${theme.colors.strongRed};
    }

    :first-child {
      border-radius: ${theme.radius.small};
    }
    :last-child {
      border-radius: ${theme.radius.small};
    }
  `}
`;
