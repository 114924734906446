import styled, { css } from 'styled-components';

export const MainContainer = styled.main`
  width: 100%;
  background: linear-gradient(
      to top,
      rgba(0, 17, 62, 0.88),
      rgba(0, 17, 62, 0.88)
    ),
    url('images/nossa_historia/historia_009.png') no-repeat top center;
  height: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const Title = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin-top: 4rem;
    text-align: center;
    color: ${theme.colors.white};
    font-weight: bold;
    font-size: ${theme.fontSizes.xxxxLarge};
  `}
`;
