import React from 'react';
import ReactGA from 'react-ga';

import HeaderNewTab from '../../components/HeaderNewTab';
import FooterNewTab from '../../components/FooterNewTab';
import PrivacyBar from '../../components/PrivacyBar';
import Main from './Main';

const Dashboard = (): JSX.Element => {
  ReactGA.pageview(window.location.href);

  return (
    <>
      <HeaderNewTab />
      <Main />
      <FooterNewTab />
      <PrivacyBar />
    </>
  );
};

export default Dashboard;
