import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import {
  Container,
  ContentWrapper,
  ImageXLarge,
  ImageXXLarge,
  YearWrapper,
} from './styles';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1220 },
    items: 4,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1220, min: 740 },
    items: 2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 740, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const OurStoryImageMultiCarousel = (): JSX.Element => {
  return (
    <>
      <Container>
        <Carousel
          swipeable={false}
          draggable={false}
          showDots
          responsive={responsive}
          infinite
          autoPlay
          autoPlaySpeed={3000}
          customTransition="all .5"
          containerClass="carousel-container"
          removeArrowOnDeviceType={['desktop', 'tablet', 'mobile']}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          <ContentWrapper>
            <ImageXLarge
              src="images/nossa_historia/historia_001.png"
              alt="História 001"
            />
            <YearWrapper>1975</YearWrapper>
          </ContentWrapper>

          <ContentWrapper>
            <ImageXLarge
              src="images/nossa_historia/historia_002.png"
              alt="História 002"
            />
            <YearWrapper>1976</YearWrapper>
          </ContentWrapper>

          <ContentWrapper>
            <ImageXLarge
              src="images/nossa_historia/historia_003.png"
              alt="História 003"
            />
            <YearWrapper>1977</YearWrapper>
          </ContentWrapper>

          <ContentWrapper>
            <ImageXLarge
              src="images/nossa_historia/historia_004.png"
              alt="História 004"
            />
            <YearWrapper>2002</YearWrapper>
          </ContentWrapper>

          <ContentWrapper>
            <ImageXLarge
              src="images/nossa_historia/historia_005.png"
              alt="História 005"
            />
            <YearWrapper>2003</YearWrapper>
          </ContentWrapper>

          <ContentWrapper>
            <ImageXLarge
              src="images/nossa_historia/historia_006.png"
              alt="História 006"
            />
            <YearWrapper>2011</YearWrapper>
          </ContentWrapper>

          <ContentWrapper>
            <ImageXLarge
              src="images/nossa_historia/historia_007.png"
              alt="História 007"
            />
            <YearWrapper>2011</YearWrapper>
          </ContentWrapper>

          <ContentWrapper>
            <ImageXLarge
              src="images/nossa_historia/historia_008.png"
              alt="História 008"
            />
            <YearWrapper>2016</YearWrapper>
          </ContentWrapper>

          <ContentWrapper>
            <ImageXXLarge
              src="images/nossa_historia/historia_009.png"
              alt="História 009"
            />
            <YearWrapper>2020</YearWrapper>
          </ContentWrapper>
        </Carousel>
      </Container>
    </>
  );
};

export default OurStoryImageMultiCarousel;
