import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled(motion.aside)`
  ${({ theme }) => css`
    width: 100%;
    height: 0.4rem;
    background: ${theme.colors.strongRed};
  `}
`;
