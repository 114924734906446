import React, {
  createContext,
  useState,
  useCallback,
  useContext,
  PropsWithChildren,
} from 'react';

import { storageKey } from '../utils';

export type AppLanguageContext = {
  currentLanguage: string;
  handleLanguageChange(val: string): Promise<void>;
};

const AppLanguageContext = createContext<AppLanguageContext | null>(null);

export const AppLanguageProvider: React.FC = ({
  children,
}: PropsWithChildren<unknown>) => {
  const [currentLanguage, setCurrentLanguage] = useState<string>(() => {
    const storedLanguage = localStorage.getItem(storageKey('language'));
    return storedLanguage ? JSON.parse(storedLanguage) : 'portuguese';
  });

  const handleLanguageChange = useCallback(async (val: string) => {
    setCurrentLanguage(() => {
      const newLanguage = val;
      localStorage.setItem(storageKey('language'), JSON.stringify(newLanguage));

      return newLanguage;
    });
  }, []);

  const value = React.useMemo(
    () => ({
      currentLanguage,
      handleLanguageChange,
    }),
    [currentLanguage, handleLanguageChange],
  );

  return (
    <AppLanguageContext.Provider value={value}>
      {children}
    </AppLanguageContext.Provider>
  );
};

export function useAppLanguage(): AppLanguageContext {
  const context = useContext(AppLanguageContext);

  if (!context) {
    throw new Error(
      'useAppLanguage must be used within an AppLanguageProvider',
    );
  }

  return context;
}
