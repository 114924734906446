import React, { useState } from 'react';

import Separator from '~/components/Separator';
import AltSeparator from '~/components/AltSeparator';

import { useAppLanguage } from '~/hooks/language';
import { getLanguageInfo } from '~/utils';

import {
  MainContainer,
  Title,
  CertImageContent,
  CertImageWrapper,
  Content,
  InfoContent,
  InfoContentLargeScreen,
  InfoContentSmallScreen,
  Image,
} from './styles';

const Certifications = (): JSX.Element => {
  const { currentLanguage } = useAppLanguage();
  const [certification, setCertification] = useState('');

  function handleGlobalGapWhatIsItImage(): React.ReactNode {
    if (currentLanguage === 'english') {
      return <Image src="images/certificacoes/global_gap_o_que_e_en.png" />;
    }

    if (currentLanguage === 'spanish') {
      return <Image src="images/certificacoes/global_gap_o_que_e_es.png" />;
    }

    return <Image src="images/certificacoes/global_gap_o_que_e_pt.png" />;
  }

  function handleGlobalGapGoalsImage(): React.ReactNode {
    if (currentLanguage === 'english') {
      return <Image src="images/certificacoes/global_gap_objetivos_en.png" />;
    }

    if (currentLanguage === 'spanish') {
      return <Image src="images/certificacoes/global_gap_objetivos_es.png" />;
    }

    return <Image src="images/certificacoes/global_gap_objetivos_pt.png" />;
  }

  function handleGlobalGapAdvantagesImage(): React.ReactNode {
    if (currentLanguage === 'english') {
      return <Image src="images/certificacoes/global_gap_vantagens_en.png" />;
    }

    if (currentLanguage === 'spanish') {
      return <Image src="images/certificacoes/global_gap_vantagens_es.png" />;
    }

    return <Image src="images/certificacoes/global_gap_vantagens_pt.png" />;
  }

  function handleAloFreeWhatIsItImage(): React.ReactNode {
    if (currentLanguage === 'english') {
      return <Image src="images/certificacoes/alo_free_o_que_e_en.png" />;
    }

    if (currentLanguage === 'spanish') {
      return <Image src="images/certificacoes/alo_free_o_que_e_es.png" />;
    }

    return <Image src="images/certificacoes/alo_free_o_que_e_pt.png" />;
  }

  function handleAloFreeGoalsImage(): React.ReactNode {
    if (currentLanguage === 'english') {
      return <Image src="images/certificacoes/alo_free_objetivos_en.png" />;
    }

    if (currentLanguage === 'spanish') {
      return <Image src="images/certificacoes/alo_free_objetivos_es.png" />;
    }

    return <Image src="images/certificacoes/alo_free_objetivos_pt.png" />;
  }

  return (
    <MainContainer id="certifications">
      <Title>{getLanguageInfo(currentLanguage, 'certifications_title')}</Title>

      <CertImageContent>
        <CertImageWrapper
          to="certification_section"
          smooth
          offset={-150}
          onClick={() => setCertification('global_gap')}
        >
          <img
            src="images/certificacoes/global_gap.png"
            width={350}
            alt="global_gap"
          />
        </CertImageWrapper>

        <CertImageWrapper
          to="certification_section"
          smooth
          offset={-150}
          onClick={() => setCertification('alo_free')}
        >
          <img
            src="images/certificacoes/alo_free_vermelho.png"
            width={250}
            alt="alo_free"
          />
        </CertImageWrapper>
      </CertImageContent>

      <Separator />

      <div id="certification_section" />

      {certification === 'global_gap' && (
        <>
          <div
            style={{
              width: '100%',
              height: 450,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: '#26b145',
            }}
          >
            <img
              src="images/certificacoes/global_gap_branco.png"
              width={550}
              alt="global_gap_section"
            />
          </div>

          <AltSeparator />

          <Content>
            <div>{handleGlobalGapWhatIsItImage()}</div>

            <InfoContent>
              <div>
                {getLanguageInfo(
                  currentLanguage,
                  'certifications_global_gap_what_is_it_text_01',
                )}
              </div>

              <div style={{ marginTop: '2rem' }}>
                {getLanguageInfo(
                  currentLanguage,
                  'certifications_global_gap_what_is_it_text_02',
                )}
              </div>
            </InfoContent>
          </Content>

          <Content>
            <InfoContentLargeScreen>
              <div>
                {getLanguageInfo(
                  currentLanguage,
                  'certifications_global_gap_goals_text_01',
                )}
              </div>

              <div style={{ marginTop: '2rem' }}>
                {getLanguageInfo(
                  currentLanguage,
                  'certifications_global_gap_goals_text_02',
                )}
              </div>
            </InfoContentLargeScreen>

            <div>{handleGlobalGapGoalsImage()}</div>

            <InfoContentSmallScreen>
              <div>
                {getLanguageInfo(
                  currentLanguage,
                  'certifications_global_gap_goals_text_01',
                )}
              </div>

              <div style={{ marginTop: '2rem' }}>
                {getLanguageInfo(
                  currentLanguage,
                  'certifications_global_gap_goals_text_02',
                )}
              </div>
            </InfoContentSmallScreen>
          </Content>

          <Content>
            <div>{handleGlobalGapAdvantagesImage()}</div>

            <InfoContent style={{ textIndent: '0' }}>
              <div>
                {getLanguageInfo(
                  currentLanguage,
                  'certifications_global_gap_advantages_topic_01',
                )}
              </div>

              <div style={{ marginTop: '2rem' }}>
                {getLanguageInfo(
                  currentLanguage,
                  'certifications_global_gap_advantages_topic_02',
                )}
              </div>

              <div style={{ marginTop: '2rem' }}>
                {getLanguageInfo(
                  currentLanguage,
                  'certifications_global_gap_advantages_topic_03',
                )}
              </div>

              <div style={{ marginTop: '2rem' }}>
                {getLanguageInfo(
                  currentLanguage,
                  'certifications_global_gap_advantages_topic_04',
                )}
              </div>

              <div style={{ marginTop: '2rem' }}>
                {getLanguageInfo(
                  currentLanguage,
                  'certifications_global_gap_advantages_topic_05',
                )}
              </div>
            </InfoContent>
          </Content>

          <Separator />
        </>
      )}

      {certification === 'alo_free' && (
        <>
          <div
            style={{
              width: '100%',
              height: 450,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: '#e22439',
            }}
          >
            <img
              src="images/certificacoes/alo_free_branco.png"
              width={350}
              alt="alo_free_section"
            />
          </div>

          <AltSeparator />

          <Content>
            <div>{handleAloFreeWhatIsItImage()}</div>

            <InfoContent>
              <div>
                {getLanguageInfo(
                  currentLanguage,
                  'certifications_alo_free_what_is_it_text_01_part_01',
                )}

                <span style={{ fontWeight: 'bold' }}>
                  {getLanguageInfo(
                    currentLanguage,
                    'certifications_alo_free_what_is_it_text_01_part_02',
                  )}
                </span>

                {getLanguageInfo(
                  currentLanguage,
                  'certifications_alo_free_what_is_it_text_01_part_03',
                )}
              </div>

              <div style={{ marginTop: '2rem' }}>
                {getLanguageInfo(
                  currentLanguage,
                  'certifications_alo_free_what_is_it_text_02',
                )}
              </div>
            </InfoContent>
          </Content>

          <Content>
            <div>{handleAloFreeGoalsImage()}</div>

            <InfoContent style={{ textIndent: '0' }}>
              <div>
                {getLanguageInfo(
                  currentLanguage,
                  'certifications_alo_free_goals_topic_01',
                )}
              </div>

              <div style={{ marginTop: '1rem' }}>
                {getLanguageInfo(
                  currentLanguage,
                  'certifications_alo_free_goals_topic_02',
                )}
              </div>

              <div style={{ marginTop: '1rem' }}>
                {getLanguageInfo(
                  currentLanguage,
                  'certifications_alo_free_goals_topic_03',
                )}
              </div>

              <div style={{ marginTop: '1rem' }}>
                {getLanguageInfo(
                  currentLanguage,
                  'certifications_alo_free_goals_topic_04',
                )}
              </div>

              <div style={{ marginTop: '1rem' }}>
                {getLanguageInfo(
                  currentLanguage,
                  'certifications_alo_free_goals_topic_05',
                )}
              </div>

              <div style={{ marginTop: '1rem' }}>
                {getLanguageInfo(
                  currentLanguage,
                  'certifications_alo_free_goals_topic_06',
                )}
              </div>

              <div style={{ marginTop: '1rem' }}>
                {getLanguageInfo(
                  currentLanguage,
                  'certifications_alo_free_goals_topic_07',
                )}
              </div>

              <div style={{ marginTop: '1rem' }}>
                {getLanguageInfo(
                  currentLanguage,
                  'certifications_alo_free_goals_topic_08',
                )}
              </div>
            </InfoContent>
          </Content>
          <Separator />
        </>
      )}
    </MainContainer>
  );
};

export default Certifications;
