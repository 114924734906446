import React from 'react';
import { ReactSwitchProps } from 'react-switch';

import { useAppLanguage } from '~/hooks/language';
import { getLanguageInfo } from '~/utils';

import { Container, ToggleButton, Label } from './styles';

const Toggle = React.forwardRef<HTMLInputElement, ReactSwitchProps>(
  ({ checked, ...rest }: ReactSwitchProps) => {
    const { currentLanguage } = useAppLanguage();

    function getEnableDescription(): string {
      const enable_description = getLanguageInfo(
        currentLanguage,
        'toggle_enable',
      );

      if (enable_description) {
        return enable_description;
      }

      return 'Habilitar';
    }

    function getDisableDescription(): string {
      const disable_description = getLanguageInfo(
        currentLanguage,
        'toggle_disable',
      );

      if (disable_description) {
        return disable_description;
      }

      return 'Desabilitar';
    }

    return (
      <Container>
        <ToggleButton offColor="#ff0000" checked={checked} {...rest} />
        <Label isDisabled={!checked}>
          {checked ? getEnableDescription() : getDisableDescription()}
        </Label>
      </Container>
    );
  },
);

export default Toggle;
