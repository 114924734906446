import React, {
  createContext,
  useState,
  useCallback,
  useContext,
  PropsWithChildren,
} from 'react';

import { storageKey } from '../utils';

export type AppCookiesContext = {
  cookiesEnabled: boolean;
  handleCookiesChange(val: boolean): Promise<void>;
};

const AppCookiesContext = createContext<AppCookiesContext | null>(null);

export const AppCookiesProvider: React.FC = ({
  children,
}: PropsWithChildren<unknown>) => {
  const [cookiesEnabled, setCookiesEnabled] = useState<boolean>(() => {
    const storedCookies = localStorage.getItem(storageKey('cookies'));
    return storedCookies ? JSON.parse(storedCookies) : false;
  });

  const handleCookiesChange = useCallback(async (val: boolean) => {
    setCookiesEnabled(() => {
      const newCookies = val;
      localStorage.setItem(storageKey('cookies'), JSON.stringify(newCookies));

      return newCookies;
    });
  }, []);

  const value = React.useMemo(
    () => ({
      cookiesEnabled,
      handleCookiesChange,
    }),
    [cookiesEnabled, handleCookiesChange],
  );

  return (
    <AppCookiesContext.Provider value={value}>
      {children}
    </AppCookiesContext.Provider>
  );
};

export function useAppCookies(): AppCookiesContext {
  const context = useContext(AppCookiesContext);

  if (!context) {
    throw new Error('useAppCookies must be used within an AppCookiesProvider');
  }

  return context;
}
