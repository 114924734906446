import React, { InputHTMLAttributes, useState, useCallback } from 'react';
import { FiAlertOctagon } from 'react-icons/fi';

import { Container, InputContainer, Label, Error } from './styles';

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  labelFor?: string;
  labelText?: string;
  showLabel?: boolean;
  error?: string;
}

const Input = React.forwardRef<HTMLInputElement, IInputProps>(
  (
    { name, labelText, labelFor, showLabel, error, ...rest }: IInputProps,
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleInputFocus = useCallback(() => {
      setIsFocused(!isFocused);
    }, [isFocused]);

    const handleInputBlur = useCallback(() => {
      setIsFocused(false);
    }, []);

    return (
      <Container>
        {showLabel && <Label isFocused={isFocused}>{labelText}</Label>}

        <InputContainer id={labelFor} isFocused={isFocused} isErrored={!!error}>
          <input
            name={name}
            type="type"
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            ref={ref}
            {...rest}
          />
        </InputContainer>
        {error && (
          <Error title={error}>
            <FiAlertOctagon color="#c91918" size={20} />
          </Error>
        )}
      </Container>
    );
  },
);

export default Input;
