import React from 'react';

import HomeImageMultiCarousel from '~/components/HomeImageMultiCarousel';

import { Container } from './styles';

const Home = (): JSX.Element => {
  return (
    <Container id="home">
      <HomeImageMultiCarousel />
    </Container>
  );
};

export default Home;
