import React from 'react';

import Separator from '~/components/Separator';

import { useAppLanguage } from '~/hooks/language';
import { getLanguageInfo } from '~/utils';

import {
  MainContainer,
  ContentContainer,
  ContentTitle,
  ContentSubtitle,
  ContentTextWrapper,
  ContentText,
  ContentHighlightedText,
  ContentItemsWrapper,
  ContentItemWrapper,
  ContentItemIndexWrapper,
  ContentItemInfoWrapper,
  ContentItemIndexFirstType,
  ContentItemTitleFirstType,
  ContentItemText,
} from './styles';

const Main = (): JSX.Element => {
  const { currentLanguage } = useAppLanguage();

  return (
    <MainContainer id="privacypolicy">
      <ContentContainer>
        <ContentTitle>
          {getLanguageInfo(currentLanguage, 'privacypolicy_main_title')}
        </ContentTitle>

        <ContentTextWrapper>
          <ContentText>
            {getLanguageInfo(currentLanguage, 'privacypolicy_main_text_01')}
          </ContentText>
        </ContentTextWrapper>

        <ContentTextWrapper>
          <ContentText>
            {getLanguageInfo(currentLanguage, 'privacypolicy_main_text_02')}
          </ContentText>
        </ContentTextWrapper>

        <ContentTextWrapper>
          <ContentText>
            {getLanguageInfo(currentLanguage, 'privacypolicy_main_text_03')}
          </ContentText>
        </ContentTextWrapper>

        <ContentTextWrapper>
          <ContentHighlightedText>
            {getLanguageInfo(currentLanguage, 'privacypolicy_main_note')}
          </ContentHighlightedText>
        </ContentTextWrapper>

        <ContentTextWrapper>
          <ContentText>
            {getLanguageInfo(currentLanguage, 'privacypolicy_main_text_04')}
          </ContentText>
        </ContentTextWrapper>

        <ContentTextWrapper>
          <ContentText>
            {getLanguageInfo(currentLanguage, 'privacypolicy_main_text_05')}
          </ContentText>
        </ContentTextWrapper>

        <ContentTextWrapper>
          <ContentText>
            {getLanguageInfo(currentLanguage, 'privacypolicy_main_text_06')}
          </ContentText>
        </ContentTextWrapper>
      </ContentContainer>

      <ContentContainer>
        <ContentSubtitle>
          {getLanguageInfo(currentLanguage, 'privacypolicy_definitions_title')}
        </ContentSubtitle>

        <ContentTextWrapper>
          <ContentText>
            {getLanguageInfo(
              currentLanguage,
              'privacypolicy_definitions_text_01',
            )}
          </ContentText>
        </ContentTextWrapper>

        <ContentItemsWrapper>
          <ContentItemWrapper>
            <ContentItemIndexWrapper>
              <ContentItemIndexFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_definitions_item_a_index',
                )}
              </ContentItemIndexFirstType>
            </ContentItemIndexWrapper>

            <ContentItemInfoWrapper>
              <ContentItemTitleFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_definitions_item_a_title',
                )}
              </ContentItemTitleFirstType>

              <ContentItemText>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_definitions_item_a_text',
                )}
              </ContentItemText>
            </ContentItemInfoWrapper>
          </ContentItemWrapper>

          <ContentItemWrapper>
            <ContentItemIndexWrapper>
              <ContentItemIndexFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_definitions_item_b_index',
                )}
              </ContentItemIndexFirstType>
            </ContentItemIndexWrapper>

            <ContentItemInfoWrapper>
              <ContentItemTitleFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_definitions_item_b_title',
                )}
              </ContentItemTitleFirstType>

              <ContentItemText>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_definitions_item_b_text',
                )}
              </ContentItemText>
            </ContentItemInfoWrapper>
          </ContentItemWrapper>

          <ContentItemWrapper>
            <ContentItemIndexWrapper>
              <ContentItemIndexFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_definitions_item_c_index',
                )}
              </ContentItemIndexFirstType>
            </ContentItemIndexWrapper>

            <ContentItemInfoWrapper>
              <ContentItemTitleFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_definitions_item_c_title',
                )}
              </ContentItemTitleFirstType>

              <ContentItemText>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_definitions_item_c_text',
                )}
              </ContentItemText>
            </ContentItemInfoWrapper>
          </ContentItemWrapper>

          <ContentItemWrapper>
            <ContentItemIndexWrapper>
              <ContentItemIndexFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_definitions_item_d_index',
                )}
              </ContentItemIndexFirstType>
            </ContentItemIndexWrapper>

            <ContentItemInfoWrapper>
              <ContentItemTitleFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_definitions_item_d_title',
                )}
              </ContentItemTitleFirstType>

              <ContentItemText>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_definitions_item_d_text',
                )}
              </ContentItemText>
            </ContentItemInfoWrapper>
          </ContentItemWrapper>

          <ContentItemWrapper>
            <ContentItemIndexWrapper>
              <ContentItemIndexFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_definitions_item_e_index',
                )}
              </ContentItemIndexFirstType>
            </ContentItemIndexWrapper>

            <ContentItemInfoWrapper>
              <ContentItemTitleFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_definitions_item_e_title',
                )}
              </ContentItemTitleFirstType>

              <ContentItemText>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_definitions_item_e_text',
                )}
              </ContentItemText>
            </ContentItemInfoWrapper>
          </ContentItemWrapper>
        </ContentItemsWrapper>
      </ContentContainer>

      <ContentContainer>
        <ContentSubtitle>
          {getLanguageInfo(
            currentLanguage,
            'privacypolicy_personal_data_title',
          )}
        </ContentSubtitle>

        <ContentTextWrapper>
          <ContentText>
            {getLanguageInfo(
              currentLanguage,
              'privacypolicy_personal_data_text_01',
            )}
          </ContentText>
        </ContentTextWrapper>

        <ContentItemsWrapper>
          <ContentItemWrapper>
            <ContentItemIndexWrapper>
              <ContentItemIndexFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_personal_data_item_01_index',
                )}
              </ContentItemIndexFirstType>
            </ContentItemIndexWrapper>

            <ContentItemInfoWrapper>
              <ContentItemText>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_personal_data_item_01_text',
                )}
              </ContentItemText>
            </ContentItemInfoWrapper>
          </ContentItemWrapper>

          <ContentItemWrapper>
            <ContentItemIndexWrapper>
              <ContentItemIndexFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_personal_data_item_02_index',
                )}
              </ContentItemIndexFirstType>
            </ContentItemIndexWrapper>

            <ContentItemInfoWrapper>
              <ContentItemText>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_personal_data_item_02_text',
                )}
              </ContentItemText>
            </ContentItemInfoWrapper>
          </ContentItemWrapper>

          <ContentItemWrapper>
            <ContentItemIndexWrapper>
              <ContentItemIndexFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_personal_data_item_03_index',
                )}
              </ContentItemIndexFirstType>
            </ContentItemIndexWrapper>

            <ContentItemInfoWrapper>
              <ContentItemText>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_personal_data_item_03_text',
                )}
              </ContentItemText>
            </ContentItemInfoWrapper>
          </ContentItemWrapper>

          <ContentItemWrapper>
            <ContentItemIndexWrapper>
              <ContentItemIndexFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_personal_data_item_04_index',
                )}
              </ContentItemIndexFirstType>
            </ContentItemIndexWrapper>

            <ContentItemInfoWrapper>
              <ContentItemText>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_personal_data_item_04_text',
                )}
              </ContentItemText>
            </ContentItemInfoWrapper>
          </ContentItemWrapper>

          <ContentItemWrapper>
            <ContentItemIndexWrapper>
              <ContentItemIndexFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_personal_data_item_05_index',
                )}
              </ContentItemIndexFirstType>
            </ContentItemIndexWrapper>

            <ContentItemInfoWrapper>
              <ContentItemText>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_personal_data_item_05_text',
                )}
              </ContentItemText>
            </ContentItemInfoWrapper>
          </ContentItemWrapper>
        </ContentItemsWrapper>

        <ContentTextWrapper>
          <ContentText>
            {getLanguageInfo(
              currentLanguage,
              'privacypolicy_personal_data_text_02',
            )}
          </ContentText>
        </ContentTextWrapper>

        <ContentItemsWrapper>
          <ContentItemWrapper>
            <ContentItemIndexWrapper>
              <ContentItemIndexFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_personal_data_item_a_index',
                )}
              </ContentItemIndexFirstType>
            </ContentItemIndexWrapper>

            <ContentItemInfoWrapper>
              <ContentItemText>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_personal_data_item_a_text',
                )}
              </ContentItemText>
            </ContentItemInfoWrapper>
          </ContentItemWrapper>

          <ContentItemWrapper>
            <ContentItemIndexWrapper>
              <ContentItemIndexFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_personal_data_item_b_index',
                )}
              </ContentItemIndexFirstType>
            </ContentItemIndexWrapper>

            <ContentItemInfoWrapper>
              <ContentItemText>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_personal_data_item_b_text',
                )}
              </ContentItemText>
            </ContentItemInfoWrapper>
          </ContentItemWrapper>

          <ContentItemWrapper>
            <ContentItemIndexWrapper>
              <ContentItemIndexFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_personal_data_item_c_index',
                )}
              </ContentItemIndexFirstType>
            </ContentItemIndexWrapper>

            <ContentItemInfoWrapper>
              <ContentItemText>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_personal_data_item_c_text',
                )}
              </ContentItemText>
            </ContentItemInfoWrapper>
          </ContentItemWrapper>

          <ContentItemWrapper>
            <ContentItemIndexWrapper>
              <ContentItemIndexFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_personal_data_item_d_index',
                )}
              </ContentItemIndexFirstType>
            </ContentItemIndexWrapper>

            <ContentItemInfoWrapper>
              <ContentItemText>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_personal_data_item_d_text',
                )}
              </ContentItemText>
            </ContentItemInfoWrapper>
          </ContentItemWrapper>

          <ContentItemWrapper>
            <ContentItemIndexWrapper>
              <ContentItemIndexFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_personal_data_item_e_index',
                )}
              </ContentItemIndexFirstType>
            </ContentItemIndexWrapper>

            <ContentItemInfoWrapper>
              <ContentItemText>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_personal_data_item_e_text',
                )}
              </ContentItemText>
            </ContentItemInfoWrapper>
          </ContentItemWrapper>

          <ContentItemWrapper>
            <ContentItemIndexWrapper>
              <ContentItemIndexFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_personal_data_item_f_index',
                )}
              </ContentItemIndexFirstType>
            </ContentItemIndexWrapper>

            <ContentItemInfoWrapper>
              <ContentItemText>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_personal_data_item_f_text',
                )}
              </ContentItemText>
            </ContentItemInfoWrapper>
          </ContentItemWrapper>

          <ContentItemWrapper>
            <ContentItemIndexWrapper>
              <ContentItemIndexFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_personal_data_item_g_index',
                )}
              </ContentItemIndexFirstType>
            </ContentItemIndexWrapper>

            <ContentItemInfoWrapper>
              <ContentItemText>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_personal_data_item_g_text',
                )}
              </ContentItemText>
            </ContentItemInfoWrapper>
          </ContentItemWrapper>

          <ContentItemWrapper>
            <ContentItemIndexWrapper>
              <ContentItemIndexFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_personal_data_item_h_index',
                )}
              </ContentItemIndexFirstType>
            </ContentItemIndexWrapper>

            <ContentItemInfoWrapper>
              <ContentItemText>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_personal_data_item_h_text',
                )}
              </ContentItemText>
            </ContentItemInfoWrapper>
          </ContentItemWrapper>

          <ContentItemWrapper>
            <ContentItemIndexWrapper>
              <ContentItemIndexFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_personal_data_item_h_index',
                )}
              </ContentItemIndexFirstType>
            </ContentItemIndexWrapper>

            <ContentItemInfoWrapper>
              <ContentItemText>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_personal_data_item_h_text',
                )}
              </ContentItemText>
            </ContentItemInfoWrapper>
          </ContentItemWrapper>

          <ContentItemWrapper>
            <ContentItemIndexWrapper>
              <ContentItemIndexFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_personal_data_item_i_index',
                )}
              </ContentItemIndexFirstType>
            </ContentItemIndexWrapper>

            <ContentItemInfoWrapper>
              <ContentItemText>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_personal_data_item_i_text',
                )}
              </ContentItemText>
            </ContentItemInfoWrapper>
          </ContentItemWrapper>
        </ContentItemsWrapper>
      </ContentContainer>

      <ContentContainer>
        <ContentSubtitle>
          {getLanguageInfo(
            currentLanguage,
            'privacypolicy_non_provided_personal_data_title',
          )}
        </ContentSubtitle>

        <ContentTextWrapper>
          <ContentText>
            {getLanguageInfo(
              currentLanguage,
              'privacypolicy_non_provided_personal_data_text_01',
            )}
          </ContentText>
        </ContentTextWrapper>
      </ContentContainer>

      <ContentContainer>
        <ContentSubtitle>
          {getLanguageInfo(
            currentLanguage,
            'privacypolicy_collected_data_title',
          )}
        </ContentSubtitle>

        <ContentTextWrapper>
          <ContentText>
            {getLanguageInfo(
              currentLanguage,
              'privacypolicy_collected_data_text_01',
            )}
          </ContentText>
        </ContentTextWrapper>

        <ContentTextWrapper>
          <ContentText>
            {getLanguageInfo(
              currentLanguage,
              'privacypolicy_collected_data_text_02',
            )}
          </ContentText>
        </ContentTextWrapper>

        <ContentItemsWrapper>
          <ContentItemWrapper>
            <ContentItemIndexWrapper>
              <ContentItemIndexFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_collected_data_item_01_index',
                )}
              </ContentItemIndexFirstType>
            </ContentItemIndexWrapper>

            <ContentItemInfoWrapper>
              <ContentItemText>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_collected_data_item_01_text',
                )}
              </ContentItemText>
            </ContentItemInfoWrapper>
          </ContentItemWrapper>

          <ContentItemWrapper>
            <ContentItemIndexWrapper>
              <ContentItemIndexFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_collected_data_item_02_index',
                )}
              </ContentItemIndexFirstType>
            </ContentItemIndexWrapper>

            <ContentItemInfoWrapper>
              <ContentItemText>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_collected_data_item_02_text',
                )}
              </ContentItemText>
            </ContentItemInfoWrapper>
          </ContentItemWrapper>

          <ContentItemWrapper>
            <ContentItemIndexWrapper>
              <ContentItemIndexFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_collected_data_item_03_index',
                )}
              </ContentItemIndexFirstType>
            </ContentItemIndexWrapper>

            <ContentItemInfoWrapper>
              <ContentItemText>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_collected_data_item_03_text',
                )}
              </ContentItemText>
            </ContentItemInfoWrapper>
          </ContentItemWrapper>

          <ContentItemWrapper>
            <ContentItemIndexWrapper>
              <ContentItemIndexFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_collected_data_item_04_index',
                )}
              </ContentItemIndexFirstType>
            </ContentItemIndexWrapper>

            <ContentItemInfoWrapper>
              <ContentItemText>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_collected_data_item_04_text',
                )}
              </ContentItemText>
            </ContentItemInfoWrapper>
          </ContentItemWrapper>
        </ContentItemsWrapper>

        <ContentTextWrapper>
          <ContentHighlightedText>
            {getLanguageInfo(
              currentLanguage,
              'privacypolicy_collected_data_highlighted_text',
            )}
          </ContentHighlightedText>
        </ContentTextWrapper>
      </ContentContainer>

      <ContentContainer>
        <ContentSubtitle>
          {getLanguageInfo(
            currentLanguage,
            'privacypolicy_sharing_p_d_third_parties_title',
          )}
        </ContentSubtitle>

        <ContentTextWrapper>
          <ContentText>
            {getLanguageInfo(
              currentLanguage,
              'privacypolicy_sharing_p_d_third_parties_text_01',
            )}
          </ContentText>
        </ContentTextWrapper>

        <ContentItemsWrapper>
          <ContentItemWrapper>
            <ContentItemIndexWrapper>
              <ContentItemIndexFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_sharing_p_d_third_parties_item_01_index',
                )}
              </ContentItemIndexFirstType>
            </ContentItemIndexWrapper>

            <ContentItemInfoWrapper>
              <ContentItemText>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_sharing_p_d_third_parties_item_01_text',
                )}
              </ContentItemText>
            </ContentItemInfoWrapper>
          </ContentItemWrapper>

          <ContentItemWrapper>
            <ContentItemIndexWrapper>
              <ContentItemIndexFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_sharing_p_d_third_parties_item_02_index',
                )}
              </ContentItemIndexFirstType>
            </ContentItemIndexWrapper>

            <ContentItemInfoWrapper>
              <ContentItemText>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_sharing_p_d_third_parties_item_02_text',
                )}
              </ContentItemText>
            </ContentItemInfoWrapper>
          </ContentItemWrapper>
        </ContentItemsWrapper>
      </ContentContainer>

      <ContentContainer>
        <ContentSubtitle>
          {getLanguageInfo(
            currentLanguage,
            'privacypolicy_international_data_transfers_title',
          )}
        </ContentSubtitle>

        <ContentTextWrapper>
          <ContentText>
            {getLanguageInfo(
              currentLanguage,
              'privacypolicy_international_data_transfers_text_01',
            )}
          </ContentText>
        </ContentTextWrapper>
      </ContentContainer>

      <ContentContainer>
        <ContentSubtitle>
          {getLanguageInfo(currentLanguage, 'privacypolicy_user_rights_title')}
        </ContentSubtitle>

        <ContentTextWrapper>
          <ContentText>
            {getLanguageInfo(
              currentLanguage,
              'privacypolicy_user_rights_text_01',
            )}
          </ContentText>
        </ContentTextWrapper>

        <ContentItemsWrapper>
          <ContentItemWrapper>
            <ContentItemIndexWrapper>
              <ContentItemIndexFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_user_rights_item_a_index',
                )}
              </ContentItemIndexFirstType>
            </ContentItemIndexWrapper>

            <ContentItemInfoWrapper>
              <ContentItemText>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_user_rights_item_a_text',
                )}
              </ContentItemText>
            </ContentItemInfoWrapper>
          </ContentItemWrapper>

          <ContentItemWrapper>
            <ContentItemIndexWrapper>
              <ContentItemIndexFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_user_rights_item_b_index',
                )}
              </ContentItemIndexFirstType>
            </ContentItemIndexWrapper>

            <ContentItemInfoWrapper>
              <ContentItemText>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_user_rights_item_b_text',
                )}
              </ContentItemText>
            </ContentItemInfoWrapper>
          </ContentItemWrapper>

          <ContentItemWrapper>
            <ContentItemIndexWrapper>
              <ContentItemIndexFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_user_rights_item_c_index',
                )}
              </ContentItemIndexFirstType>
            </ContentItemIndexWrapper>

            <ContentItemInfoWrapper>
              <ContentItemText>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_user_rights_item_c_text',
                )}
              </ContentItemText>
            </ContentItemInfoWrapper>
          </ContentItemWrapper>

          <ContentItemWrapper>
            <ContentItemIndexWrapper>
              <ContentItemIndexFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_user_rights_item_d_index',
                )}
              </ContentItemIndexFirstType>
            </ContentItemIndexWrapper>

            <ContentItemInfoWrapper>
              <ContentItemText>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_user_rights_item_d_text',
                )}
              </ContentItemText>
            </ContentItemInfoWrapper>
          </ContentItemWrapper>

          <ContentItemWrapper>
            <ContentItemIndexWrapper>
              <ContentItemIndexFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_user_rights_item_e_index',
                )}
              </ContentItemIndexFirstType>
            </ContentItemIndexWrapper>

            <ContentItemInfoWrapper>
              <ContentItemText>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_user_rights_item_e_text',
                )}
              </ContentItemText>
            </ContentItemInfoWrapper>
          </ContentItemWrapper>

          <ContentItemWrapper>
            <ContentItemIndexWrapper>
              <ContentItemIndexFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_user_rights_item_f_index',
                )}
              </ContentItemIndexFirstType>
            </ContentItemIndexWrapper>

            <ContentItemInfoWrapper>
              <ContentItemText>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_user_rights_item_f_text',
                )}
              </ContentItemText>
            </ContentItemInfoWrapper>
          </ContentItemWrapper>

          <ContentItemWrapper>
            <ContentItemIndexWrapper>
              <ContentItemIndexFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_user_rights_item_g_index',
                )}
              </ContentItemIndexFirstType>
            </ContentItemIndexWrapper>

            <ContentItemInfoWrapper>
              <ContentItemText>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_user_rights_item_g_text',
                )}
              </ContentItemText>
            </ContentItemInfoWrapper>
          </ContentItemWrapper>

          <ContentItemWrapper>
            <ContentItemIndexWrapper>
              <ContentItemIndexFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_user_rights_item_h_index',
                )}
              </ContentItemIndexFirstType>
            </ContentItemIndexWrapper>

            <ContentItemInfoWrapper>
              <ContentItemText>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_user_rights_item_h_text',
                )}
              </ContentItemText>
            </ContentItemInfoWrapper>
          </ContentItemWrapper>

          <ContentItemWrapper>
            <ContentItemIndexWrapper>
              <ContentItemIndexFirstType>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_user_rights_item_i_index',
                )}
              </ContentItemIndexFirstType>
            </ContentItemIndexWrapper>

            <ContentItemInfoWrapper>
              <ContentItemText>
                {getLanguageInfo(
                  currentLanguage,
                  'privacypolicy_user_rights_item_i_text',
                )}
              </ContentItemText>
            </ContentItemInfoWrapper>
          </ContentItemWrapper>
        </ContentItemsWrapper>

        <ContentTextWrapper>
          <ContentText>
            {getLanguageInfo(
              currentLanguage,
              'privacypolicy_user_rights_text_02',
            )}
          </ContentText>
        </ContentTextWrapper>

        <ContentTextWrapper>
          <ContentText>
            {getLanguageInfo(
              currentLanguage,
              'privacypolicy_user_rights_text_03',
            )}
          </ContentText>
        </ContentTextWrapper>

        <ContentTextWrapper>
          <ContentText>
            {getLanguageInfo(
              currentLanguage,
              'privacypolicy_user_rights_text_04',
            )}
          </ContentText>
        </ContentTextWrapper>
      </ContentContainer>

      <ContentContainer>
        <ContentSubtitle>
          {getLanguageInfo(
            currentLanguage,
            'privacypolicy_personal_data_security_title',
          )}
        </ContentSubtitle>

        <ContentTextWrapper>
          <ContentText>
            {getLanguageInfo(
              currentLanguage,
              'privacypolicy_personal_data_security_text_01',
            )}
          </ContentText>
        </ContentTextWrapper>
      </ContentContainer>

      <ContentContainer>
        <ContentSubtitle>
          {getLanguageInfo(
            currentLanguage,
            'privacypolicy_links_other_websites_social_networks_title',
          )}
        </ContentSubtitle>

        <ContentTextWrapper>
          <ContentText>
            {getLanguageInfo(
              currentLanguage,
              'privacypolicy_links_other_websites_social_networks_text_01',
            )}
          </ContentText>
        </ContentTextWrapper>

        <ContentTextWrapper>
          <ContentText>
            {getLanguageInfo(
              currentLanguage,
              'privacypolicy_links_other_websites_social_networks_text_02',
            )}
          </ContentText>
        </ContentTextWrapper>
      </ContentContainer>

      <ContentContainer>
        <ContentSubtitle>
          {getLanguageInfo(
            currentLanguage,
            'privacypolicy_contact_information_title',
          )}
        </ContentSubtitle>

        <ContentTextWrapper>
          <ContentText>
            {getLanguageInfo(
              currentLanguage,
              'privacypolicy_contact_information_text_01',
            )}
          </ContentText>
        </ContentTextWrapper>
      </ContentContainer>

      <ContentContainer>
        <ContentSubtitle>
          {getLanguageInfo(currentLanguage, 'privacypolicy_approval_title')}
        </ContentSubtitle>

        <ContentTextWrapper>
          <ContentText>
            {getLanguageInfo(currentLanguage, 'privacypolicy_approval_text_01')}
          </ContentText>
        </ContentTextWrapper>
      </ContentContainer>

      <ContentContainer>
        <ContentSubtitle>
          {getLanguageInfo(
            currentLanguage,
            'privacypolicy_privacy_policy_updates_title',
          )}
        </ContentSubtitle>

        <ContentTextWrapper>
          <ContentText>
            {getLanguageInfo(
              currentLanguage,
              'privacypolicy_privacy_policy_updates_text_01',
            )}
          </ContentText>
        </ContentTextWrapper>

        <ContentTextWrapper>
          <ContentText>
            {getLanguageInfo(
              currentLanguage,
              'privacypolicy_privacy_policy_updates_text_02',
            )}
          </ContentText>
        </ContentTextWrapper>
      </ContentContainer>

      <ContentContainer>
        <ContentSubtitle>
          {getLanguageInfo(
            currentLanguage,
            'privacypolicy_personal_data_processing_responsable_title',
          )}
        </ContentSubtitle>

        <ContentTextWrapper>
          <ContentText>
            {getLanguageInfo(
              currentLanguage,
              'privacypolicy_personal_data_processing_responsable_text_01',
            )}
          </ContentText>
        </ContentTextWrapper>
      </ContentContainer>

      <ContentContainer>
        <ContentTextWrapper>
          <ContentHighlightedText>
            {getLanguageInfo(
              currentLanguage,
              'privacypolicy_end_highlighted_text',
            )}
          </ContentHighlightedText>
        </ContentTextWrapper>
      </ContentContainer>

      <Separator />
    </MainContainer>
  );
};

export default Main;
